//detailDataContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { detailDataNutne, detailDataRest } from '../api/detailData'; // Import detailData function
import { useLocation } from 'react-router-dom';
import { DataContext } from './baseData';

// Vytvoření kontextu
export const DetailDataContext = createContext({
    selectedDates: {},
    setSelectedDates: () => {},
    inputEditLongs: {},
    setInputEditLongs: () => {},
    inputRests: {},
    setInputRests: () => {},
    inputCalc: {},
    setInputCalc: () => {},
    inputLookups: {},
    setInputLookups: () => {},
    inputUrlOdkaz: {},
    setInputUrlOdkaz: () => {},
    inputFiles: {},
    setInputFiles: () => {},
    inputDefaults: {},
    setInputDefaults: () => {},
    vzorce: [],
    setVzorce: () => {},
    isChanged: false, // Add state for ischanged
    setIsChanged: () => {},
    inputChanged: false, // Add state for inputChanged
    setInputChanged: () => {},
    detailData: null, // Add state for detailData
    inputsAll: {},
    setInputsAll: () => {},
    aktualDetailSection: null,
    setAktualDetailSection: () => {},
    
});

// Vytvoření poskytovatele kontextu
export const DetailDataProvider = ({ children }) => {
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, paramsLAllAll, ukol} = useContext(DataContext);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [selectedDates, setSelectedDates] = useState(null);
    const [webhookSuccessful, setWebhookSuccessful] = useState(false);
    const [inputEditLongs, setInputEditLongs] = useState(null);
    const [inputRests, setInputRests] = useState(null);
    const [inputCalc, setInputCalc] = useState(null);
    const [inputLookups, setInputLookups] = useState(null);
    const [inputUrlOdkaz, setInputUrlOdkaz] = useState(null);
    const [inputFiles, setInputFiles] = useState(null);
    const [inputDefaults, setInputDefaults] = useState(null);
    const [detailDataStateNutne, setDetailDataStateNutne] = useState(null);
    const [detailDataStateRest, setDetailDataStateRest] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [inputChanged, setInputChanged] = useState(false);
    const [isTabChanged, setIsTabChanged] = useState(false);    
    const [aktualDetailSection, setAktualDetailSection] = useState(null);
    const [pole1Data, setPole1Data] = useState([]);
    const [pole1DataAppView, setPole1DataAppView] = useState([]);
    const [idKalkulace, setIdKalkulace] = useState(null);
    const [mapaData, setMapaData] = useState([]);
    const [inputBool, setInputBool] = useState(false);
    const [inputsAll, setInputsAll] = useState(null);
    const [vzorce, setVzorce] = useState([]);
    const location = useLocation();

    const [dataKalkulaceZdrojKp, setDataKalkulaceZdrojKp] = useState([]);
    const [dataKalkulaceZdrojKs1, setDataKalkulaceZdrojKs1] = useState([]);
    const [dataKalkulaceZdrojKs2, setDataKalkulaceZdrojKs2] = useState([]);

    const params = new URLSearchParams(location.search);
    const [tableEntita, setTableEntita] = useState(params.get('tableEntita'));
    const [page, setPage] = useState(params.get('page'));
    const [idUn, setIdUn] = useState(params.get('idUn')); //id jednotky v tabulce Entity
    const [entita, setEntita] = useState(params.get('entita')); //oznaceni tabulky
    //console.log('000000 v detailDataContext - tableEntita', tableEntita, 'idUn', idUn, 'page', page, 'entita', entita, isChanged);
    
    //console.log('000000 v detailDataContext - tableEntita', tableEntita, 'idUn', idUn, 'page', page);

    useEffect(() => {
        // Call detailData function on component mount

        const fetchData = async () => {
            try {
                console.log('000000 v detailDataContext - structureGlob: ', structureGlob);
                //console.log('11111 v detailDataContext volam fci detailData', tableEntita, idUn, page, aktualDetailSection);
                const detailDataNutneRes = await detailDataNutne(tableEntita, idUn, page, structureGlob, paramsLAllAll);
                setDetailDataStateNutne(detailDataNutneRes);
                console.log('22222 v detailDataContext dostavam detailDataNutneRes', detailDataNutneRes);

                const detailDataRestRes = await detailDataRest (tableEntita, idUn, page, detailDataNutneRes.paramsLGlob, detailDataNutneRes.dataGlob);
                console.log('33333 v detailDataContext dostavam detailDataRestRes', detailDataRestRes);
                setDetailDataStateRest(detailDataRestRes);
                
            } catch (error) {
                console.error('Chyba při načítání dat:', error);
            }
            
        };
        if(tableEntita && idUn && page && structureGlob.length > 0 && paramsLAllAll.length > 0) {
            fetchData();
        }
    }, [idUn, page, tableEntita, structureGlob, paramsLAllAll]);
    

    // Objekt s daty a funkcemi, které budou dostupné ve všech komponentách
    const store = {
        saveSuccessful,
        setSaveSuccessful,
        webhookSuccessful,
        setWebhookSuccessful,
        selectedDates,
        setSelectedDates,
        inputEditLongs,
        setInputEditLongs,
        inputRests,
        setInputRests,
        inputCalc,
        setInputCalc,
        inputLookups,
        setInputLookups,
        inputUrlOdkaz,
        setInputUrlOdkaz,
        inputFiles,
        setInputFiles,
        inputDefaults,
        setInputDefaults,
        detailDataNutne: detailDataStateNutne,
        detailDataRest: detailDataStateRest,
        tableEntita,
        setTableEntita,
        page,
        setPage,
        idUn,
        setIdUn,
        entita,
        setEntita,
        aktualDetailSection,
        setAktualDetailSection,
        isChanged,
        setIsChanged,
        inputChanged,
        setInputChanged,
        isTabChanged,
        setIsTabChanged,
        inputBool,
        setInputBool,
        pole1Data,
        setPole1Data,
        pole1DataAppView,
        setPole1DataAppView,
        idKalkulace,
        setIdKalkulace,
        mapaData,
        setMapaData,
        inputsAll,
        setInputsAll,
        dataKalkulaceZdrojKp,
        setDataKalkulaceZdrojKp,
        dataKalkulaceZdrojKs1,
        setDataKalkulaceZdrojKs1,
        dataKalkulaceZdrojKs2,
        setDataKalkulaceZdrojKs2,
        vzorce,
        setVzorce,
    };

    return (
        <DetailDataContext.Provider value={store}>
            {children}
        </DetailDataContext.Provider>
    );
};
