import React, { useState, useRef, useEffect } from 'react';
import { localeText } from '../atoms/localeText';
import { AgGridReact } from 'ag-grid-react';
import { TextField } from '@mui/material';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgChart } from 'ag-charts-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import dayjs from 'dayjs';
import FormatCZK from '../atoms/FormatCZK';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

LicenseManager.setLicenseKey('YOUR_LICENSE_KEY');
AgChart.licenceKey = 'YOUR_LICENSE_KEY';

// Registrace RowGroupingModule
ModuleRegistry.registerModules([RowGroupingModule, ExcelExportModule]);

const GlobalFilter = React.forwardRef(({ onFilterChanged }, ref) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
      <TextField
        type="search"
        onChange={(e) => onFilterChanged(e.target.value)}
        placeholder={'Hledej...'}
        variant="outlined"
        size="small"
        ref={ref}
        style={{ width: '100%', maxWidth: '300px' }}
        autoComplete="off"
      />
    </div>
  );
});

const DataTable = ({ tableEntita, pageP, data, headers, columnAccessors, onRowClick, mjs, inputFormat }) => {
  console.log('v poleTable - headers', headers, 'data', data, 'columnAccessors', columnAccessors, 'mjs', mjs, 'inputFormat', inputFormat);
  const gridRef = useRef(null);
  const [filterText, setFilterText] = useState("");

  const onGridReady = (params) => {
    gridRef.current = params.api;
    params.api.sizeColumnsToFit();
  };

  // Použití efektu k aktualizaci filtru v ag-Gridu
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.setQuickFilter(filterText);
    }
  }, [filterText]);

  // Vlastní renderer pro buňky, který zobrazuje pět parametrů vertikálně v jednom řádku
  const customRowRenderer = (params) => {
    if (!params.data) {
      return null;
    }

    const fieldsToShow = Object.keys(params.data).slice(0, 5); // Prvních pět parametrů
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', lineHeight: '1.4', padding: '3px 0' }}>
        {fieldsToShow.map((field, index) => {
          const value = params.data[field];
          const mj = mjs[index];
          const format = inputFormat[index];
          console.log('------------value', value, 'mj', mj, 'format', format);

          let formattedValue;
          if (!value) {
            formattedValue = 'N/A';
          } else if (mj === 'Kč') {
            formattedValue = FormatCZK(value);
          } else if (format === 'date') {
            formattedValue = dayjs(value).format('DD.MM.YYYY');
          } else if (format === 'dateTime') {
            formattedValue = dayjs(value).format('DD.MM.YYYY HH:mm');
          } else {
            formattedValue = value.toString();
          }

          return (
            <div key={index} style={{ marginBottom: '1px', fontSize: '14px' }}> {/* Nastavení většího písma */}
              {headers[index]}: <span style={{ fontSize: '16px' }}>{formattedValue}</span> {/* Zvýšení velikosti písma hodnot */}
            </div>
          );
        })}
      </div>
    );
  };

  // Definice jediného širokého sloupce pro zobrazení dat
  const columnDefs = [
    {
      headerName: '',
      field: 'custom',
      cellRenderer: customRowRenderer,
      onCellClicked: (params) => {
        if (onRowClick) {
          const { data } = params;
          if (data) {
            onRowClick(tableEntita, data.entita, pageP, data.id);
          }
        }
      },
      autoHeight: true,
      flex: 1,
      headerComponent: null, // Odstraní hlavičku sloupce
    },
  ];

  const exportToExcel = () => {
    if (gridRef.current) {
      gridRef.current.exportDataAsExcel();
    }
  };

  return (
    <div className="flex-container" style={{ width: '100%', height: '100%' }}>
      <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          onGridReady={onGridReady}
          animateRows={true}
          localeText={localeText}
          groupIncludeFooter={true}
          groupIncludeTotalFooter={true}
          domLayout="autoHeight"
          suppressColumnHeaders={true} // Potlačení hlavičky všech sloupců
          headerHeight={0} // Nastavení výšky hlavičky na 0, což ji zcela odstraní
        />
      </div>
    </div>
  );
};

export default DataTable;
