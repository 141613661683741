import React, {useContext, useState, useEffect} from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import  FormPropsTextFields from '../atoms/FormPropsTextFields';
import RestForm from '../atoms/RestForm';
import FormatCZK from '../atoms/FormatCZK';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useLocation, useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { vi } from 'date-fns/locale';


//createDefault(paramsL[j], header, dataDefaultGlobOne, placement, paramsL[j].zdroj, paramsL[j].zdrojParametr);
//param, header, rowData, placementRef 
const DefaultComponent = ({ param, placementRef }) => {
    // Přiřazení hodnoty inputu na základě dostupných dat
    const token = localStorage.getItem('token');
    const {
        inputDefaults,
        setInputDefaults,
        inputLookups,
        detailDataNutne,
        detailDataRest,
        inputChanged,
        inputRests
    } = useContext(DetailDataContext); // Získání hodnot z kontextu
    const navigate = useNavigate(); // Inicializace useNavigate

    const [inputValue, setInputValue] = useState(''); // definice stavu
    const [idSpravceEncoded, setIdSpravceEncoded] = useState('');
    const [idNemCelekEncoded, setIdNemCelekEncoded] = useState('');
    const [idVlastnikEncoded, setIdVlastnikEncoded] = useState('');
    const [idNajemSmlouvaEncoded, setIdNajemSmlouvaEncoded] = useState('');

    //console.log('v DefaultComponent - inputDefaults', inputDefaults);
    
    const inputDefault = inputDefaults?.[param.id];
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne.headers; // Získání dat z detailDataContext
    //console.log('0000000000000 - v DefaultComponent - detailDataRest', detailDataRest);
    const dataDefaultGlob = detailDataRest?.dataDefaultGlob; // Získání dat z detailDataContext
    const zdrojPar = param.zdrojParametr;
    const zdrojParRidicihoPar = param.zdrojParRidicihoPar;
    const header = param.parametr;
    const isId = header.substring(0, 2) === 'id'; // Zjistí, zda je parametr typu id, kontrluja prvni dva znaky
    const isVizu = header.substring(2, 6) === 'Vizu'; // Zjistí, zda je parametr typu vizu, kontrluja prvni dva znaky
    const vizuEntita = isVizu ? param.zdroj : null // kdyz je parametr typu vizu, tak se zjistuje, ktera entita ma byt zobrazena
    const stringAfterId = header.substring(2); // Získání stringu za id; pouziji pro odkaz na Entitu, protoze u dafaultniho inputu je zdroj v tabulce param jinak
    const tableEntita = param.tableEntita;
    //console.log('v DefaultComponent - ', 'header:', header, 'zdrojPar:', zdrojPar, 'zdrojParRidicihoPar:', zdrojParRidicihoPar, 'isId:', isId, 'isVizu:', isVizu, 'vizuEntita:', vizuEntita, 'tableEntita:', tableEntita, 'stringAfterId:', stringAfterId);
    const zdroj = param.zdroj;
    const isReadOnly = true;

    // Zakódování hodnot pomocí Base64
    
    let vizuPageEncoded = '';
    if (tableEntita === 'ES') {
        //page z looker studia
        vizuPageEncoded = Buffer.from('17daf286-affc-482a-a9ed-5591bc46be01').toString('base64');
    }else if (tableEntita === 'NemCelek') {
        vizuPageEncoded = Buffer.from('4592247e-d9ad-4a2e-a5e0-e552021a71f4').toString('base64');
    }else if (tableEntita === 'Nemovitost') {
        vizuPageEncoded = Buffer.from('b4af540c-bf72-4bbd-a1bc-ce616d04326f').toString('base64');
    }else if (tableEntita === 'NajemSmlouva') {
        vizuPageEncoded = Buffer.from('06d4a884-2400-4112-9b23-482bb9050dd2').toString('base64');
    }

    //console.log('v DefaultComponent - ', 'header:', header, 'zdrojPar:', zdrojPar, 'zdrojParRidicihoPar:', zdrojParRidicihoPar, 'isId:', isId, 'isVizu:', isVizu, 'vizuEntita:', vizuEntita);
    
    //console.log('v DefaultComponent - header: ', header, 'isId: ', isId, 'isVizu: ', isVizu, 'viuEntita: ', vizuEntita)
    
    //console.log('v DefaultComponent - placementRef', placementRef);
    //console.log('00000000000 - v DefaultComponent - dataDefaultGlob', dataDefaultGlob.default, 'header:', header, 'zdrojPar:', zdrojPar, 'zdrojParRidicihoPar:', zdrojParRidicihoPar, 'isId:', isId);
    
    useEffect(() => {
        //console.log('000000 - v DefaultComponent - useEffect: ', param.vizu);
        const ridiciParametr = param.ridiciParametr;
        const ridiciParametrValue = rowData[ridiciParametr];
        //console.log('zdrojParRidicihoPar:', zdrojParRidicihoPar, 'ridiciParametrValue:', ridiciParametrValue);
        const dataDefaultGlobBase = dataDefaultGlob?.default[param.zdroj]
        //console.log('1 - v DefaultComponent - useEffect - dataDefaultGlobBase', dataDefaultGlobBase );
        const dataDefaultGlobOne = dataDefaultGlobBase?.filter(item => item[zdrojParRidicihoPar] == ridiciParametrValue)
        //const newInputValue = dataDefaultGlobOne.length > 0 ? dataDefaultGlobOne[0][zdrojPar] : '';
        //console.log('2 - v DefaultComponent - useEffect - zdrojPar', zdrojPar, 'dataDefaultGlob', dataDefaultGlob, inputChanged, inputDefault, param.vizu, 'dataDefaultGlobOne', dataDefaultGlobOne);   

        if (inputDefault == null && param && param.parametr && !inputChanged && dataDefaultGlob) {
            //console.log('2a - v DefaultComponent - useEffect - v setInputDefault - dataDefaultGlob', dataDefaultGlob, param.vizu);
            setInputDefaults(prevValues => ({
                ...prevValues,
                [param.id]: (dataDefaultGlobOne?.length > 0 && zdrojPar) ? dataDefaultGlobOne[0][zdrojPar] : '',
            }));
        }
        
        //------------- hodnoty pro vizualizaci es-es a es-pm ve wp
        if (inputLookups && tableEntita === 'ES' && inputLookups['4406']) {
            const idSpravce = String(inputLookups['4406']); // Převod čísla na řetězec
            const idSpravceEncodedRes = Buffer.from(idSpravce).toString('base64'); // Zakódování hodnoty pomocí Base64, nemuze to byt number ale musi to byt string
            setIdSpravceEncoded(idSpravceEncodedRes);
        }

        if (inputLookups && tableEntita === 'Nemovitost' && inputLookups['4382']) {
            const idVlastnik = String(inputLookups['4382']); // Převod čísla na řetězec
            const idVlastnikEncodedRes = Buffer.from(idVlastnik).toString('base64');
            setIdVlastnikEncoded(idVlastnikEncodedRes);
        }

        if(tableEntita === 'NemCelek' && inputDefaults ) {
            console.log('3 - v DefaultComponent - useEffect - inputDefaults[4354]', inputDefaults['4354']);
            const idNemCelek = String(inputDefaults['4354']); // Převod čísla na řetězec
            const idNemCelekEncodedres = Buffer.from(idNemCelek).toString('base64');
            setIdNemCelekEncoded(idNemCelekEncodedres);

        }

        if(tableEntita === 'NajemSmlouva' && inputDefaults ) {
            console.log('3 - v DefaultComponent - useEffect - inputDefaults[4667]', inputDefaults['4667']);
            const idNajemSmlouva = String(inputDefaults['4667']); // Převod čísla na řetězec
            const idNajemSmlouvaEncodedres = Buffer.from(idNajemSmlouva).toString('base64');
            setIdNajemSmlouvaEncoded(idNajemSmlouvaEncodedres);

        }
        //---------
        
        //console.log('3 - v DefaultComponent - useEffect - inputDefaults', inputDefaults);

        //setInputDefaults({[param.id]: newInputValue || ''}); // aktualizace stavu
       

    }, [param, placementRef, JSON.stringify(inputLookups), dataDefaultGlob, inputDefaults ]);

    //console.log('4 - v DefaultComponent - inputDefaults', inputDefaults, param.vizu);
        
    return (
        
                            
        <RestForm
            value={
                (!inputDefaults || !param || inputDefaults[param.id] === undefined || inputDefaults[param.id] == null) 
                ? '' 
                : param.mj === 'Kč' 
                    ? FormatCZK(inputDefaults[param.id]) 
                    : param.format === 'date' 
                    ? dayjs(inputDefaults[param.id]).format('DD.MM.YYYY') // Předpokládám, že chcete formátovat datum
                    : inputDefaults[param.id]
            }
            required={false}
            id={`input_${header}`}
            label={param.vizu}
            variant="outlined"
            unit={param.mj ? param.mj : ''}
            helperText={''}
            readOnly={true}
            disableUnderline={true}
            style={{
                margin: '15px 0 0px 0',
                width: '320px',
                height: '40px',
                backgroundColor: 'transparent',
                
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center', // Přidáno pro vertikální zarovnání
                //width: 'calc(100% - 20px)', // Upraveno, aby byl oděr po obou stranách
                padding: '0px 10px', // Stejný padding na obě strany
                boxSizing: 'border-box',
              }}
            
            paramInput={param.input}
            iconZadani={
                isId && !isVizu && param && inputDefaults && inputDefaults[param?.id] 
                ? (
                    <IconButton
                        onClick={() => window.open(`/detail-view?tableEntita=${stringAfterId}&idUn=${inputDefaults[param.id]}&entita=&page=${param.page}`, '_blank')}
                    >
                        <LinkIcon />
                    </IconButton>
                    ) 
                : isVizu && param && inputDefaults && inputDefaults[param?.id] && vizuEntita === 'Nabidka'
                ? (
                    <IconButton
                        onClick={() => window.open(`https://energienadosah.cz/nabidka?tableEntita=${encodeURIComponent(tableEntita)}&token=${encodeURIComponent(token)}&idNabidka=${encodeURIComponent(inputDefaults[param.id])}&authPage=${encodeURIComponent(param.page)}&authCustomEntita=&authIdPage=`, '_blank')}
                    >
                        <ViewModuleIcon />
                    </IconButton>
                    )
                : isVizu && param && inputDefaults && inputDefaults[param?.id] && vizuEntita === 'Protokol'
                ? (
                    <IconButton
                        onClick={() => window.open(`https://energienadosah.cz/sablona?tableEntita=${encodeURIComponent(tableEntita)}&token=${encodeURIComponent(token)}&idProtokol=${encodeURIComponent(inputDefaults[param.id])}&idWpSablona=${encodeURIComponent(inputDefaults['4307'])}&idOP=${encodeURIComponent(inputRests['4320'])}&authPage=${encodeURIComponent(param.page)}&authCustomEntita=&authIdPage=`, '_blank')}
                    >
                        <ViewModuleIcon />
                    </IconButton>
                    )
                : isVizu && param && inputDefaults && inputDefaults[param?.id] && (vizuEntita === 'ES' || vizuEntita === 'NemCelek')
                ? (
                    <IconButton
                        onClick={() => window.open(`https://energienadosah.cz/esvizu?idSpravce=${encodeURIComponent(idSpravceEncoded)}&vizuPage=${encodeURIComponent(vizuPageEncoded)}&idNemCelek=${encodeURIComponent(idNemCelekEncoded)}&authPage=customPage`, '_blank')}
                    >
                        <ViewModuleIcon />
                    </IconButton>
                    )
                : isVizu && param && inputDefaults && inputDefaults[param?.id] && (vizuEntita === 'Nemovitost' || vizuEntita === 'NajemSmlouva')
                ? (
                    <IconButton
                        onClick={() => window.open(`https://energienadosah.cz/spotrebavizu?idVlastnik=${encodeURIComponent(idVlastnikEncoded)}&vizuPage=${encodeURIComponent(vizuPageEncoded)}&idNajemSmlouva=${encodeURIComponent(idNajemSmlouvaEncoded)}&authPage=customPage`, '_blank')}
                    >
                        <ViewModuleIcon />
                    </IconButton>
                    )
                : null
            }
            />

            
        
                        
    );
};

export default DefaultComponent;

/*
<FormPropsTextFields
                                required={false}
                                id={`input_${header}`}
                                label={param.vizu}
                                defaultValue={(inputDefaults && inputDefaults[param.id]) || ''}
                                variant="outlined"
                                readOnly={true}
                                unit={param.mj ? param.mj : null}
                                helperText="Nápověda"
                                style={null}
                            />

required={required}
          id={id}
          label={label}
          defaultValue={defaultValue}
          variant={variant}
          helperText={helperText}
          InputProps={{
            readOnly: {readOnly},
          }}

<div className="panel1">
            <div className="panel2">
                <div className="lookup1">
                    <label className="labelName">{param.vizu}</label>
                    <div className="lookup2">
                        <div className="lookup3">
                            <input
                                type="text"
                                className="input2"
                                name={header}
                                id={`input_${header}`}
                                value={(inputDefaults && inputDefaults[param.id]) || ''}
                                readOnly
                                style={{ backgroundColor: 'transparent', border: 'none', outline: 'none' }}
                            />
                        </div>
                        <li id={`mj_${header}`} className="mj">
                            {param.mj}
                        </li>
                    </div>
                </div>
            </div>
        </div>
*/
