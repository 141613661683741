// V souboru listView.js

import React, { useState, useEffect, useCallback, useContext  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataList, paramsList, paramsLLimit, getDataStructurEnt, checkTaskStatus, getDataListFromBigQuery } from '../api/apiRest';
import { DataContext } from '../store/baseData';
import { GenContext } from '../store/genData';
import { Typography } from '@mui/material';
import theme from '../theme';
import ViewSectionComponent from '../molecules/ViewSectionComponent';
import ProcesView from '../organisms/ProcesView';
import LinearIndeterminate from '../atoms/LinearIndeterminate';
import DataTable from '../organisms/listTableMui1'; // Importujte komponentu DataTable
import ProcesGantt from '../atoms/ProcesGantt';
import { set } from 'date-fns';


const ListView = () => {
    // globalni parametry
    //const { structurEntGlob } = useContext(DataContext);
    //const { paramsListGlob } = useContext(DataContext);
    
    const [data, setData] = useState([]);      // Data pro zobrazení v listu
    const [headers, setHeaders] = useState([]); // hlavicka tabulky
    const [mjs, setMjs] = useState([]); // jednotky
    const [inputFormat, setInputFormat] = useState([]); // format vstupu [date, number
    const [headersParam, setHeadersParam] = useState([]); // parametry radku
    const [zdrojDB, setZdrojDB] = useState(''); // zdroj DB [enadodb, Intranet, bigquery]
    const [entityTitle, setEntityTitle] = useState('Načítám data...');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate(); // Inicializace useNavigate
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, setCiselnikGlob,
         procesAll, aktualViewSection, setAktualViewSection, paramsLAllAll,
          setParamsLAllAll } = useContext(DataContext);
    
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    useEffect(() => {
        setIsHeaderMenuVisible(true);
    }, [setIsHeaderMenuVisible]);

    const tableEntita = params.get('tableEntita');
    const page = params.get('page');

    const favicon = '/enadoSlunicko.png'
    let viewSection

    if (tableEntita === 'OPB') {
        viewSection = ['Přehled', 'Proces', 'Nástěnka', 'Procesní mapy'];
    } else {
        viewSection = ['Přehled', 'Proces', 'Nástěnka'];
    }

    //console.log('v listView ciselnikGlob', ciselnikGlob);
    //console.log('v listView paramsLAllAll', paramsLAllAll);


    useEffect(() => {
        
        if (paramsLAllAll) {

            const paramsL = paramsLAllAll.filter(obj => obj.tableEntita === tableEntita);    
            const paramsLL = paramsLLimit(paramsL, 'listViewTab');
            const headers = paramsLL.map(objekt => objekt.vizu);
            const mjs = paramsLL.map(objekt => objekt.mj);
            const zdrojDB = paramsLL[0]?.zdrojDB;
            console.log('v listView - useEffect - zdrojDB', zdrojDB);
            //const inputFormat = paramsLL.map(objekt => objekt.input);
            const inputFormat = paramsLL.map(objekt => objekt.format);
            const headersParam = paramsLL.map(objekt => objekt.parametr);
            
            setHeaders(headers);
            setMjs(mjs);
            setInputFormat(inputFormat);
            setHeadersParam(headersParam);
            setZdrojDB(zdrojDB);
        }
        if(structureGlob){
            const structurEnt = structureGlob.filter(obj => obj.tableEntita === tableEntita);
            if (structurEnt && structurEnt.length > 0) {
                setEntityTitle(structurEnt[0].entites || 'Neznámá entita');
            }
        }
        
                        
    }, [paramsLAllAll]);
    
    
    const fetchData = useCallback(async () => {
        const token = localStorage.getItem('token');
        console.log('v listView - fetchData - zdrojDB', zdrojDB);
        if(zdrojDB) {

            if(zdrojDB == 'bigquery') {
                console.log('v listView - fetchData - getDataListFromBigQuery', tableEntita, page);
                const dataList = await getDataListFromBigQuery(tableEntita, page, token);
                setData(dataList);
            }else{
                try {
                    
                    console.log('v listView - fetchData - getDataList', tableEntita, page);
                    let response1 = await getDataList(tableEntita, page, token);
                    const taskId1 = response1.taskId;
                    const dataList = await checkTaskStatus(taskId1, 'listView');
                    
                    setData(dataList.data);
                    //console.log('v listView dataList', dataList);
                    
            
                } catch (error) {
                    console.error('Chyba při načítání dat:', error);
                    console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
                    if (error.message.includes('/logout') || error.message.includes('Invalid Token')) {
                        window.location.href = '/logout';
                    }
                }
            }
        }
        
    
      }, [tableEntita, page, zdrojDB]); // Závislosti pro useCallback
    
          
      useEffect(() => {
          fetchData();
    }, [fetchData, ]);



    // Funkce pro přesměrování na detail entity
    const handleEntityClick = (tableEntita, entita, page, id) => {
        // Implementace navigace
        // Můžete použít 'useNavigate' z 'react-router-dom' nebo jiný způsob navigace
        
        //navigate(`/detail-view?tableEntita=${tableEntita}&idUn=${id}&entita=${entita}&page=${page}`);
        window.open(`/detail-view?tableEntita=${tableEntita}&idUn=${id}&entita=${entita}&page=${page}`, '_blank');
        
       
    };

    

    if (!ciselnikGlob.length || !paramsLAllAll.length || !data.length || !headers.length || !headersParam.length ) {
        return <LinearIndeterminate/>;
    } else {    
    
    return (
        <div>
            <Typography 
                variant="h6" 
                onClick={() => navigate("/board-view")}
                sx={{
                    backgroundColor: 'none',
                    fontSize: '18px',
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '2px 0 2px 0',
                    cursor: 'pointer',
                    width: '100%',
                    zIndex: '100',
                    textDecoration: 'none',
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                    
                                        
                    '&:hover': {
                        borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    },
                }}
                >
                {entityTitle}
            </Typography>

            <ViewSectionComponent className="detail-section"
                    dataLookup={viewSection}
            />
            
            {aktualViewSection === 'Přehled' && 
            <DataTable tableEntita={tableEntita} pageP={page} data={data} headers={headers} columnAccessors={headersParam} onRowClick={handleEntityClick} mjs={mjs} inputFormat={inputFormat} />
            }
            {aktualViewSection === 'Proces' && 
            <ProcesView tableEntita={tableEntita} data={data}/>
            }
            {aktualViewSection === 'Procesní mapy' && tableEntita === 'OPB' &&
            <ProcesGantt tableEntita={tableEntita} entryPoint={'listProcesMapa'} />
            }
            
        </div>
    );
    }
};

export default ListView;

