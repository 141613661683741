import React, { useEffect, useContext } from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import LinkIcon from '@mui/icons-material/Link';

const UrlOdkazComponent = ({ param }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1C80BC',
      },
    },
  });

  const {
    inputUrlOdkaz,
    setInputUrlOdkaz,
    detailDataNutne,
    setIsChanged,
    isChanged
  } = useContext(DetailDataContext);

  const rowData = detailDataNutne?.dataGlob[0];
  const header = param.parametr;

  useEffect(() => {
    if (rowData?.[header] && !inputUrlOdkaz?.[param.id] && !isChanged) {
      setInputUrlOdkaz(prevValues => ({
        ...prevValues,
        [param.id]: rowData[header],
      }));
    }
  }, [param, rowData, header, inputUrlOdkaz, setInputUrlOdkaz]);

  const handleUrlDelete = () => {
    setInputUrlOdkaz(prevValues => ({
      ...prevValues,
      [param.id]: null,
    }));
    setIsChanged(true);
  };

  const handleUrlChange = (event) => {
    const url = event.target.value;
    setInputUrlOdkaz(prevValues => ({
      ...prevValues,
      [param.id]: url,
    }));
    setIsChanged(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-start',
          margin: '15px 0px 15px 10px',
          width: '95%',
          height: '100%',
          backgroundColor: 'inherit',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          label={param.vizu}
          type="url"
          value={inputUrlOdkaz?.[param.id] || ''}
          onChange={handleUrlChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ 'aria-label': param.vizu }}
          sx={{
            width: '300px',
            marginRight: '10px',
          }}
        />
        {inputUrlOdkaz?.[param.id] && (
          <IconButton component="a" href={inputUrlOdkaz[param.id]} target="_blank" rel="noopener noreferrer">
            <LinkIcon />
          </IconButton>
        )}
        {inputUrlOdkaz?.[param.id] && (
          <IconButton onClick={handleUrlDelete}><DeleteIcon /></IconButton>
        )}
        
      </Box>
    </ThemeProvider>
  );
};

export default UrlOdkazComponent;
