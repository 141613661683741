// detailData.jsgetKalkulacePolozka
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DataContext } from '../store/baseData';
import TableKalk from '../organisms/tableKalk';
import TableKalk1 from '../organisms/tableKalk1';
import { getKalkulacePolozka, checkTaskStatus } from '../api/apiRest';
import { DetailDataContext } from '../store/detailDataContext';
import LinearIndeterminate from '../atoms/LinearIndeterminate';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import theme from '../theme';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-modal';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Alert from '@mui/material/Alert';
import { da, id, ta } from 'date-fns/locale';
//import Spocitej from './KalkulaceSpocitej';









export default function KalkulaceTable({ param, placementRef, shouldCalculate, onCalculationDone }) {

    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, paramsLAllAll} = useContext(DataContext);
    //console.log('00 - v KalkulaceTable - paramsLAllAll', paramsLAllAll);

    const {
        saveSuccessful,
        setSaveSuccessful,
        webhookSuccessful,
        setWebhookSuccessful,
        tableEntita,
        page,
        idUn,
        entita,
        aktualDetailSection,
        detailDataNutne,
        detailDataRest,
        inputDefaults,
        inputEditLongs,
        vzorce,
        setVzorce,
        inputRests,
        setInputRests,
        inputLookups,
        setInputLookups,
        inputFiles,
        selectedDates,
        isChanged,
        setIsChanged,
        inputChanged,
        setInputChanged,
        isTabChanged,
        setIsTabChanged,
        inputBool,
        setInputBool,
        pole1Data,
        setPole1Data,
        pole1DataAppView,
        setPole1DataAppView,
        idKalkulace,
        setIdKalkulace,
        inputsAll,
        setInputsAll,
        dataKalkulaceZdrojKp,
        setDataKalkulaceZdrojKp,
        dataKalkulaceZdrojKs1,
        setDataKalkulaceZdrojKs1,
        dataKalkulaceZdrojKs2,
        setDataKalkulaceZdrojKs2,
    } = useContext(DetailDataContext); // Získání hodnot z kontextu
    
    const [dataKalkulaceZdroj, setDataKalkulaceZdroj] = useState([]);
    

    const [headers, setHeaders] = useState([]);
    const [columnAccessors, setColumnAccessors] = useState([]);
    const [mjs, setMjs] = useState('');
    const [inputFormat, setInputFormat] = useState('');
       
    
    const [addIconId, setAddIconId] = useState(null);
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate(); // Inicializace useNavigate
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState('success');
       

    // pole parametru ktere budou vizualizovany v KalkulaceTable
    //console.log('12 - v KalkulaceTable - paramsLAllAll', paramsLAllAll);
    
    const [paramsLKalkulacniPolozkaBase0VizuPocet, setParamsLKalkulacniPolozkaBase0VizuPocet] = useState([]);
    const [paramsLKalkulacniPolozkaBase0Pocet, setParamsLKalkulacniPolozkaBase0Pocet] = useState([]);
    const [paramsLKalkulacniPolozkaBase0MjPocet, setParamsLKalkulacniPolozkaBase0MjPocet] = useState([]);
    const [paramsLKalkulacniPolozkaBase0FormatPocet, setParamsLKalkulacniPolozkaBase0FormatPocet] = useState([]);

    const [paramsLKalkulacniSkupina1Base0VizuPocet, setParamsLKalkulacniSkupina1Base0VizuPocet] = useState([]);
    const [paramsLKalkulacniSkupina1Base0Pocet, setParamsLKalkulacniSkupina1Base0Pocet] = useState([]);
    const [paramsLKalkulacniSkupina1BaseMj, setParamsLKalkulacniSkupina1BaseMj] = useState([]);

    const [paramsLKalkulacniSkupina2Base0VizuPocet, setParamsLKalkulacniSkupina2Base0VizuPocet] = useState([]);
    const [paramsLKalkulacniSkupina2Base0Pocet, setParamsLKalkulacniSkupina2Base0Pocet] = useState([]);
    const [paramsLKalkulacniSkupina2BaseMj, setParamsLKalkulacniSkupina2BaseMj] = useState([]);

    
    //const paramPole1 = paramsLAllAll.find(param => param.input === 'pole1' && param.tableEntita === tableEntita)|| '';
    let pole1DataInput; // pole dat vstupujici do zobrazeni v tabulce
    let rowData
    let dataDefaultProvize
    let idIdent;
    //let appFilter
    //aplikace Kalkulace
    const appParamId = 'appId' + tableEntita;
    let idKalkulaceRes
    let appKalkulace = false;
    const paramKalkulacePole1Data = paramsLAllAll.find(param => param.parametr === 'kalkulacePole1Data' && param.tableEntita === tableEntita) || '';
    const paramIdKalkulace = paramsLAllAll.find(param => param.parametr === 'idKalkulace' && param.tableEntita === tableEntita)?.id || '';
    const rowDataAllKalkulace = detailDataRest?.dataDefaultGlob.default.Kalkulace || '';
    
    
    const dataNutne = detailDataNutne; // Získání dat z detailDataContext
    //console.log('00 - v KalkulaceTable - dataNutne', dataNutne);
    //console.log('00 - v KalkulaceTable - inputDefault', inputDefaults);
    //console.log('00 - v KalkulaceTable - inputRest', inputRests);
    //console.log('00 - v KalkulaceTable - inputLookups', inputLookups);
    //console.log('00 - v KalkulaceTable - detailDataRest.dataDefaultGlob.default.ProvizeTarif', detailDataRest?.dataDefaultGlob?.default.ProvizeTarif)
    //console.log('00 - v KalkulaceTable - paramsAll', paramsLAllAll);
    //console.log('00 - v KalkulaceTable - detailDataNutne', detailDataNutne);
    //console.log('00 - v KalkulaceTable - detailDataRest', detailDataRest);
    //console.log('00 - v KalkulaceTable - idKalkulace', idKalkulace);
    //console.log('00 - v KalkulaceTable - param.id', param.id);
   
    //console.log('00 - v KalkulaceTable - paramKalkulacePole1Data', paramKalkulacePole1Data, 'paramIdKalkulace', paramIdKalkulace, 'idKalkulaceRes', idKalkulaceRes);
    
    if(aktualDetailSection === 'Kalkulace') { // plati jen pro ne kalkulacni entity
        //aplikace Kalkulace
        
        //idKalkulaceRes = inputLookups?.[paramIdKalkulace] || inputDefaults?.[paramIdKalkulace] || ''; // nepotrebne
        //setIdKalkulace(idKalkulaceRes);
        //console.log('00 - v KalkulaceTable - aktualDetailSection', aktualDetailSection);
        idIdent = '2088'
        
        appKalkulace = true;
        //kalkulaceId = dataNutne[idKalkulaceRes]
        //rowData = detailDataRest?.dataDefaultGlob.default.Kalkulace.find(item => item.id === idKalkulaceRes) || '';
        
        //console.log('00 - v KalkulaceTable - rowData', rowData);
        

    }else{ // v entite KalkulaceSkupina1 a KalkulaceSkupina2 a Kalkulace  neni detailSection Kalkulace
        //console.log('10 - v KalkulaceTable - dataNutne', dataNutne);
        rowData = detailDataNutne?.dataGlob[0]; // Získání dat z detailDataContext
        
        if (tableEntita === 'Kalkulace') {idIdent = '2088'} else if(tableEntita === 'KalkulacniSkupina1') {idIdent = '2066'} else if(tableEntita === 'KalkulacniSkupina2') {idIdent = '2071'}
        
    }

    const paramsL = detailDataNutne?.paramsLGlob; // Získání dat z detailDataContext
    let idCenaCelkemBezDph = paramsL.find(item => item.parametr === 'cenaCelkemBezDph')?.id;
    let idCenaCelkemSDph = paramsL.find(item => item.parametr === 'cenaCelkemSDph')?.id;
    let idHrubaMarze = paramsL.find(item => item.parametr === 'hrubaMarze')?.id;
    let idCistaMarze = paramsL.find(item => item.parametr === 'cistaMarze')?.id;
    let idHrubaMarzeNaZH = paramsL.find(item => item.parametr === 'hrubaMarzeNaZH')?.id;
    let idCistaMarzeNaZH = paramsL.find(item => item.parametr === 'cistaMarzeNaZH')?.id;
    let idZakazkoveHodiny = paramsL.find(item => item.parametr === 'zakazkoveHodiny')?.id;
    let idNakladyPrace = paramsL.find(item => item.parametr === 'nakladyPrace')?.id;
    let idNakladyNePrace = paramsL.find(item => item.parametr === 'nakladyNePrace')?.id;
    let idVykonFve = paramsL.find(item => item.parametr === 'vykonFve')?.id;
    let idVykonTc = paramsL.find(item => item.parametr === 'vykonTc')?.id;
    let idKapacitaBat = paramsL.find(item => item.parametr === 'kapacitaBat')?.id;
    let idPocetPanelu = paramsL.find(item => item.parametr === 'pocetPanelu')?.id;
    let idNakladyLead = paramsL.find(item => item.parametr === 'nakladyLead')?.id;
    let idNakladySmlouva = paramsL.find(item => item.parametr === 'nakladySmlouva')?.id;
    const idFixL = paramsL.find(item => item.parametr === 'fixL')?.id;
    const idP1L = paramsL.find(item => item.parametr === 'p1L')?.id;
    const idP2L = paramsL.find(item => item.parametr === 'p2L')?.id;
    const idVzorecL = paramsL.find(item => item.parametr === 'vzorecL')?.id;
    const idFixS = paramsL.find(item => item.parametr === 'fixS')?.id;
    const idP1S = paramsL.find(item => item.parametr === 'p1S')?.id;
    const idP2S = paramsL.find(item => item.parametr === 'p2S')?.id;
    const idVzorecS = paramsL.find(item => item.parametr === 'vzorecS')?.id;
    const idProvizeTarifL = paramsL.find(item => item.parametr === 'idProvizeTarifL')?.id;
    const idProvizeTarifS = paramsL.find(item => item.parametr === 'idProvizeTarifS')?.id;
    
    
    //zakladni a jedinej vstup dat do tabulky
    if(!appKalkulace){
        pole1DataInput = pole1Data[idIdent] || ''; 
    } else {
        //aplikace Kalkulace
        //pole1DataAppView je pole pouzivane jako stavove v pripade aplikace kalkulace, umoznuje to filtrovat data pro zobrazeni v tab a pri tom v pole1Data uchovavat data pro ulozeni v kalkulaci
        pole1DataInput = pole1DataAppView[idIdent] || '';
        
    }
    
    //console.log('00 - v KalkulaceTable - pole1Data', pole1Data, isTabChanged);
    //console.log('00 - v KalkulaceTable - ppole1DataAppView[idIdent]', pole1DataAppView[idIdent]);
    //console.log('01 - v KalkulaceTable - pole1DataInput', pole1DataInput);
    //console.log('00 - v KalkulaceTable - inputsAll', inputsAll);
   
    const keyMappingKp = {
        kpJc: 'jc',
        kpName: 'name',
        kpId: 'id',
        kpMnAdd: 'mn',
        kpMj: 'mj',
        
    };
    
    const keyMappingKs1 = {
        ks1Name: 'name',
        ks1Id: 'id',
        ks1MnAdd: 'ks1MnAdd',
        kpMnAdd: 'kpMnAdd'
        
    };

    // pozor neni vyreseno pro RealizacniCinnost a SoD OPS
    const parovanaEntitaTable = {
        ServisZasah: 'Servis',
        RealizacniCinnost: 'OPB',
        Objednavka: 'Objednavka',
        Reklamace: 'Reklamace',
        OPB: 'OPB',
        Servis: 'Servis',
        SoD: 'OPB',
        ZalohaFakturace: 'OPB',
        OPS: 'OPS',
        Stitek: 'OPB',
        SmlouvaServis: 'OPB',
    }
    
    //volano z DetailView
    useEffect(() => {
        if (shouldCalculate) {
            spocitej(tableEntita, pole1Data[2088])
        }
    }, [shouldCalculate]);

    useEffect(() => {
        // Kontrola, že všechny potřebné proměnné jsou definovány a mají požadovanou délku
        console.log('14 - v KalkulaceTable - tableEntita', tableEntita);
        if(paramsLAllAll){
            

            // Získání parametrů pro vizualizaci-----------------------------------------------

            //Kalkulační položka - kp-----------------------------------------------------------------------------------------
            let paramsLKalkulacniPolozkaBase1 = ['kpId', 'kpName', 'kpMj', 'kpJc']
            let paramsLKalkulacniPolozkaBase1Vizu = ['id položky', 'název položky', 'mj položky', 'jednotková cena']
            const paramsLKalkulacniPolozkaBase = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka' && param.parametr.includes('kp'))
                .map(param => param.parametr);
            //console.log('13 - v KalkulaceTable - paramsLKalkulacniPolozkaBase', paramsLKalkulacniPolozkaBase);
            const paramsLKalkulacniPolozkaBaseVizu = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka' && param.parametr.includes('kp'))
                .map(param => param.vizu);
            const paramsLKalkulacniPolozkaBaseMj = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka' && param.parametr.includes('kp'))
                .map(param => param.mj)
            const paramsLKalkulacniPolozkaBaseMjPocet = ['', ...paramsLKalkulacniPolozkaBaseMj]
            const paramsLKalkulacniPolozkaBaseFormat = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka' && param.parametr.includes('kp'))
                .map(param => param.format);
            const paramsLKalkulacniPolozkaBaseFormatPocet = ['', ...paramsLKalkulacniPolozkaBaseFormat]
            
            const paramsLKalkulacniPolozkaBase0 = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka')
                .map(param => param.parametr);
            const paramsLKalkulacniPolozkaBase0PocetRes = ['kpMnAdd', ...paramsLKalkulacniPolozkaBase0]
            setParamsLKalkulacniPolozkaBase0Pocet(paramsLKalkulacniPolozkaBase0PocetRes);
            const paramsLKalkulacniPolozkaBase0Vizu = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka')
                .map(param => param.vizu);
            const paramsLKalkulacniPolozkaBase0VizuPocetRes = ['Počet položky', ...paramsLKalkulacniPolozkaBase0Vizu]
            setParamsLKalkulacniPolozkaBase0VizuPocet(paramsLKalkulacniPolozkaBase0VizuPocetRes);
            const paramsLKalkulacniPolozkaBase0Mj = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka')
                .map(param => param.mj);
            const paramsLKalkulacniPolozkaBase0MjPocetRes = ['', ...paramsLKalkulacniPolozkaBase0Mj]
            setParamsLKalkulacniPolozkaBase0MjPocet(paramsLKalkulacniPolozkaBase0MjPocetRes);
            const paramsLKalkulacniPolozkaBase0Format = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniPolozka')
                .map(param => param.format);
            const paramsLKalkulacniPolozkaBase0FormatPocetRes = ['', ...paramsLKalkulacniPolozkaBase0Format]
            setParamsLKalkulacniPolozkaBase0FormatPocet(paramsLKalkulacniPolozkaBase0FormatPocetRes);
                
            

            // Podskupina - sk1---------------------------------------------------------------------------------------------
            const paramsLKalkulacniSkupina1Base0 = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina1')
                .map(param => param.parametr);
            const paramsLKalkulacniSkupina1Base0PocetRes = ['ks1MnAdd', ...paramsLKalkulacniSkupina1Base0]
            setParamsLKalkulacniSkupina1Base0Pocet(paramsLKalkulacniSkupina1Base0PocetRes);
            const paramsLKalkulacniSkupina1Base0Vizu = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina1')
                .map(param => param.vizu);
            const paramsLKalkulacniSkupina1Base0VizuPocetRes = ['Počet podskupiny', ...paramsLKalkulacniSkupina1Base0Vizu]
            setParamsLKalkulacniSkupina1Base0VizuPocet(paramsLKalkulacniSkupina1Base0VizuPocetRes);
            const paramsLKalkulacniSkupina1BaseMjRes = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina1')
                .map(param => param.mj); 
            setParamsLKalkulacniSkupina1BaseMj(paramsLKalkulacniSkupina1BaseMjRes);
            let paramsLKalkulacniSkupina1Base1 = ['ks1Id', 'ks1Name', 'ks1Mj']
            let paramsLKalkulacniSkupina1Base1Vizu = ['id podskupiny', 'název podskupiny', 'mj podskupiny']
            let paramsLKalkulacniSkupina1Base = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina1' && param.parametr.includes('ks1'))
                .map(param => param.parametr);
            paramsLKalkulacniSkupina1Base = ['ks1Id', ...paramsLKalkulacniSkupina1Base]
            let paramsLKalkulacniSkupina1BaseVizu = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina1' && param.parametr.includes('ks1'))
                .map(param => param.vizu);
            paramsLKalkulacniSkupina1BaseVizu = ['id podskupiny', ...paramsLKalkulacniSkupina1BaseVizu]
        
            // Skupina - sk2---------------------------------------------------------------------------------------------------
            const paramsLKalkulacniSkupina2Base0 = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina2')
                .map(param => param.parametr);
            const paramsLKalkulacniSkupina2Base0PocetRes = ['ks2MnAdd', ...paramsLKalkulacniSkupina2Base0]
            setParamsLKalkulacniSkupina2Base0Pocet(paramsLKalkulacniSkupina2Base0PocetRes);
            const paramsLKalkulacniSkupina2Base0Vizu = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina2')
                .map(param => param.vizu);
            const paramsLKalkulacniSkupina2Base0VizuPocetRes = ['Počet Skupiny', ...paramsLKalkulacniSkupina2Base0Vizu]
            setParamsLKalkulacniSkupina2Base0VizuPocet(paramsLKalkulacniSkupina2Base0VizuPocetRes);
            let paramsLKalkulacniSkupina2Base1 = ['ks2Id', 'ks2Name', 'ks2Mj']
            let paramsLKalkulacniSkupina2Base1Vizu = ['id skupiny', 'název skupiny', 'mj skupiny']
            let paramsLKalkulacniSkupina2Base = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina2' && param.parametr.includes('ks2'))
                .map(param => param.parametr);
            paramsLKalkulacniSkupina2Base = ['ks2Id', ...paramsLKalkulacniSkupina2Base]
            let paramsLKalkulacniSkupina2BaseVizu = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina2' && param.parametr.includes('ks2'))
                .map(param => param.vizu);
            paramsLKalkulacniSkupina2BaseVizu = ['id skupiny', ...paramsLKalkulacniSkupina2BaseVizu]
            const paramsLKalkulacniSkupina2BaseMjRes = paramsLAllAll
                .filter(param => param.tableEntita === 'KalkulacniSkupina2')
                .map(param => param.mj); 
            setParamsLKalkulacniSkupina2BaseMj(paramsLKalkulacniSkupina2BaseMjRes);
            const paramsLKalkulaceBase = paramsLAllAll
                .filter(param => param.tableEntita === 'Kalkulace' && param.parametr.includes('kalkulace'))
                .map(param => param.parametr);
            const paramsLKalkulaceBaseVizu = paramsLAllAll
                .filter(param => param.tableEntita === 'Kalkulace' && param.parametr.includes('kalkulace'))
                .map(param => param.vizu);
            const paramsLKalkulaceBaseMj = paramsLAllAll
                .filter(param => param.tableEntita === 'Kalkulace' && param.parametr.includes('kalkulace'))
                .map(param => param.mj);
            //console.log('13 - v KalkulaceTable - paramsLKalkulaceBase', paramsLKalkulaceBase, 'paramsLKalkulaceBaseVizu', paramsLKalkulaceBaseVizu);

            // Podskupina - sk1---------------------------------------------------------------------------------------------
            const paramsLKalkulacniSkupina1Add = ['kpCenaCalc', 'kpP31Calc'] // stejne i pro Tab
            const paramsLKalkulacniSkupina1 = [
                //...paramsLKalkulacniSkupina1Base,
                ...paramsLKalkulacniPolozkaBase1, 'kpMnAdd',
                ...paramsLKalkulacniSkupina1Add,
                ...paramsLKalkulacniPolozkaBase,
            ]
            const paramsLKalkulacniSkupina1AddVizu = ['Nákladová cena celkem', 'P31 Celkem']
            const paramsLKalkulacniSkupina1Vizu = [
                //...paramsLKalkulacniSkupina1BaseVizu,
                ...paramsLKalkulacniPolozkaBase1Vizu, 'Počet položky',
                ...paramsLKalkulacniSkupina1AddVizu,
                ...paramsLKalkulacniPolozkaBaseVizu,
            ]
            const paramsLKalkulacniSkupina1Mj = [
                '', '', '', 'Kč', '',
                'Kč',
                ...paramsLKalkulacniPolozkaBaseMjPocet,

            ]
            const paramsLKalkulacniSkupina1Format = [
                '', '', '', '',
                '', '', '',
                ...paramsLKalkulacniPolozkaBaseFormat,

            ]

            // Skupina - sk2---------------------------------------------------------------------------------------------------
            const paramsLKalkulacniSkupina2AddTab = ['kpMnCalc', 'kpCenaCalc', 'kpP31Calc']
            const paramsLKalkulacniSkupina2Tab = [
                //...paramsLKalkulacniSkupina2Base,
                
                ...paramsLKalkulacniSkupina1Base1, 'ks1MnAdd',
                ...paramsLKalkulacniPolozkaBase1, 'kpMnAdd',
                ...paramsLKalkulacniSkupina2AddTab,
                ...paramsLKalkulacniPolozkaBase,
                
            ]
            const paramsLKalkulacniSkupina2AddVizu = ['Počet celkem', 'Nákladová cena celkem', 'P31 Celkem']
            const paramsLKalkulacniSkupina2Vizu = [
                //...paramsLKalkulacniSkupina2BaseVizu,
                
                ...paramsLKalkulacniSkupina1Base1Vizu, 'Výskyt podskupiny ve skupině',
                ...paramsLKalkulacniPolozkaBase1Vizu, 'Výskyt položky v podskupině',
                ...paramsLKalkulacniSkupina2AddVizu,
                ...paramsLKalkulacniPolozkaBaseVizu,
            ]
            const paramsLKalkulacniSkupina2Add = ['kpMnBase', 'kpMnCalc', 'kpCenaCalc', 'kpP31Calc']
            const paramsLKalkulacniSkupina2 = [
                //...paramsLKalkulacniSkupina2Base,
                
                ...paramsLKalkulacniSkupina1Base1, 'ks1MnAdd',
                ...paramsLKalkulacniPolozkaBase1, 'kpMnAdd',
                ...paramsLKalkulacniSkupina2Add,
                ...paramsLKalkulacniPolozkaBase,
                
            ]
            const paramsLKalkulacniSkupina2Mj = [
                '', '', '', '',
                '', '', '', 'Kč', '',
                '', 'Kč', '',
                ...paramsLKalkulacniPolozkaBaseMj,
            ]
            const paramsLKalkulacniSkupina2Format = [
                '', '', '', '',
                '', '', '', '', '',
                '', '', '',
                ...paramsLKalkulacniPolozkaBaseFormat,
            ]
            
            // Kalkulace ----------------------------------------------------------------------
            const paramsLKalkulaceAddTab = ['kpMnCalc', 'kpCenaCalc', 'kpP31Calc',
            'kpMarzeProcento', 'kpMarzeKc', 'kpCenaCelkemSMarzi', 'kpCenaCelkemSMarziSDph']
            const paramsLKalkulaceApp = ['appIdSoD', 'appIdSmlouvaServis', 'appIdServisZasah', 'appIdRealizacniCinnost',
            'appIdObjednavka', 'appIdZalohaFakturace', 'appIdReklamace', 'appIdStitek' ]
            const paramsLKalkulaceDef = ['defIdKalkulace', 'defIdOPB', 'defProcesOPBObchod', 'defProcesOPBRealizace', 'defIdOPS', 'defProcesOPSObchod', 'defProcesOPSRealizace', 'defIdServis', 'defProcesServis']
            const paramsLKalkulaceAppVizu = ['id SoD', 'id Servisní smlouvy', 'id Servisního zásahu', 'id Realizační činnosti',
            'id Objednávky', 'id Fakturace', 'id Reklamace', 'id Štítku' ]
            const paramsLKalkulaceDefVizu = ['id Kalkulace', 'id OPB', 'OPB Proces Obchod ', 'OPB Proces Realizace', 'id OPS', 'OPS Proces Obchod', 'OPS Proces Realizace', 'id Servis', 'Servis Proces']
            const paramsLKalkulaceTab = [
                'kpOblast',
                ...paramsLKalkulacniSkupina2Base1, 'ks2MnAdd',
                ...paramsLKalkulacniSkupina1Base1, 'ks1MnAdd',
                ...paramsLKalkulacniPolozkaBase1, 'kpMnAdd',
                ...paramsLKalkulaceAddTab,
                ...paramsLKalkulacniPolozkaBase,
                ...paramsLKalkulaceApp,
                ...paramsLKalkulaceDef
            ]
            //console.log('13a - v KalkulaceTable - paramsLKalkulaceTab', paramsLKalkulaceTab);
            const paramsLKalkulaceAddVizu = ['Počet celkem', 'Nákladová cena celkem', 'P31 Celkem',
            'Marže %', 'Marže Kč', 'Cena celkem s marží', 'Cena celkem s marží s DPH']
            const paramsLKalkulaceVizu = [
                'oblast',
                ...paramsLKalkulacniSkupina2Base1Vizu, 'Výskyt skupiny v kalkulaci',
                ...paramsLKalkulacniSkupina1Base1Vizu, 'Výskyt podskupiny ve skupině',
                ...paramsLKalkulacniPolozkaBase1Vizu, 'Počet položky',
                ...paramsLKalkulaceAddVizu,
                ...paramsLKalkulacniPolozkaBaseVizu,
                ...paramsLKalkulaceAppVizu,
                ...paramsLKalkulaceDefVizu
            ]
            //console.log('13b - v KalkulaceTable - paramsLKalkulaceVizu', paramsLKalkulaceVizu);
            const paramsLKalkulaceAdd = ['ks1MnCalc', 'ks1MnBase', 'kpMnBase', 'kpMnCalc', 'kpCenaCalc', 'kpP31Calc',
            'kpMarzeProcento', 'kpMarzeKc', 'kpCenaCelkemSMarzi', 'kpCenaCelkemSMarziSDph']
            const paramsLKalkulace = [
                'kpOblast',
                ...paramsLKalkulacniSkupina2Base1, 'ks2MnAdd',
                ...paramsLKalkulacniSkupina1Base1, 'ks1MnAdd',
                ...paramsLKalkulacniPolozkaBase1, 'kpMnAdd',
                ...paramsLKalkulaceAdd,
                ...paramsLKalkulacniPolozkaBase,
            ]
            //console.log('13c - v KalkulaceTable - paramsLKalkulace', paramsLKalkulace);
            const paramsLKalkulaceMj = [
                '', '', '', '', '', '', '', '', '', '', '', '', 'Kč', '', '', 'Kč', '', '%', 'Kč', 'Kč', 'Kč', 
                ...paramsLKalkulacniPolozkaBaseMj
            ]
            const paramsLKalkulaceFormat = [
                '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'percent', '', '', '',
                ...paramsLKalkulacniPolozkaBaseFormat
            ]
            //---------------------------------------------------------------------------------------------------------------
            //console.log('14 - v KalkulaceTable - tableEntita', tableEntita, 'aktualDetailSection', aktualDetailSection);            
            switch (tableEntita) {
                case 'Kalkulace':
                    //console.log('14a - v KalkulaceTable - tableEntita', tableEntita, 'paramsLKalkulaceVizu', paramsLKalkulaceVizu, 'paramsLKalkulaceTab', paramsLKalkulaceTab);
                    setHeaders(paramsLKalkulaceVizu);
                    setColumnAccessors(paramsLKalkulaceTab);
                    setMjs(paramsLKalkulaceMj)
                    setInputFormat(paramsLKalkulaceFormat)
                    break;
                case 'KalkulacniSkupina2':
                    //console.log('14b - v KalkulaceTable - tableEntita', tableEntita, 'paramsLKalkulacniSkupina2Vizu', paramsLKalkulacniSkupina2Vizu, 'paramsLKalkulacniSkupina2Tab', paramsLKalkulacniSkupina2Tab, 'paramsLKalkulacniSkupina2Mj', paramsLKalkulacniSkupina2Mj, 'paramsLKalkulacniSkupina2Format', paramsLKalkulacniSkupina2Format);
                    setHeaders(paramsLKalkulacniSkupina2Vizu);
                    setColumnAccessors(paramsLKalkulacniSkupina2Tab);
                    setMjs(paramsLKalkulacniSkupina2Mj)
                    setInputFormat(paramsLKalkulacniSkupina2Format)
                    break;
                case 'KalkulacniSkupina1':
                    setHeaders(paramsLKalkulacniSkupina1Vizu);
                    setColumnAccessors(paramsLKalkulacniSkupina1);
                    setMjs(paramsLKalkulacniSkupina1Mj)
                    setInputFormat(paramsLKalkulacniSkupina1Format)
                    break;
                default: //stejne jako Kalkulace, mam pro pripady aplikace Kalkulace do entit
                    //console.log('14d - v KalkulaceTable - tableEntita', tableEntita, 'paramsLKalkulaceVizu', paramsLKalkulaceVizu, 'paramsLKalkulaceTab', paramsLKalkulaceTab);
                    setHeaders(paramsLKalkulaceVizu);
                    setColumnAccessors(paramsLKalkulaceTab);
                    setMjs(paramsLKalkulaceMj)
                    setInputFormat(paramsLKalkulaceFormat)
                    break;
            }
        }
        
        
    }, [paramsLAllAll]); // Prázdné závislosti znamenají, že tento efekt se spustí pouze jednou po prvním vykreslení
    
        
    const fetchDataKalkulaceZdroj= useCallback(async () => {
        try {
            let detItemX
            // -------- ziskani dat vsech polozek a skupin a podskupin v db ----------
            const token = localStorage.getItem('token');
            const response1 = await getKalkulacePolozka(token);
            const taskId1 = response1.taskId;
            const kalkulacePolozkaX = await checkTaskStatus(taskId1);
            const dataKalkulaceZdrojResNeakt = kalkulacePolozkaX.data;
            console.log('001 - v KalkulaceTable - dataKalkulaceZdrojNeakt', dataKalkulaceZdrojResNeakt);

            //aktualizace dat vsech dat, tj. podskupin a skupin
            const kp = dataKalkulaceZdrojResNeakt.kp
            //console.log('002a - v KalkulaceTable - kp', kp);
            // Aktualizace ks1
            console.log('002 - v KalkulaceTable - dataKalkulaceZdrojResNeakt.ks1', dataKalkulaceZdrojResNeakt.ks1);
            const ks1 = dataKalkulaceZdrojResNeakt.ks1.map(itemX => {
                let pole1Data = JSON.parse(itemX.pole1Data);
                pole1Data = pole1Data?.map(itemNeakt => {
                const itemPolozka = kp.find(item => item.id === itemNeakt.kpId);
                
                if (itemPolozka) {
                    const newItem = {};
                    for (let key in itemNeakt) {
                    const mappedKeyKp = keyMappingKp[key] ? keyMappingKp[key] : key;
                    //newItem[key] = itemPolozka[mappedKeyKp] ? itemPolozka[mappedKeyKp] : itemNeakt[key];
                    newItem[key] = itemPolozka[mappedKeyKp] !== undefined ? itemPolozka[mappedKeyKp] : itemNeakt[key];
                    }
                    
                    return newItem;
                } else {
                    return itemNeakt;
                }
                });
                return {...itemX, pole1Data: JSON.stringify(pole1Data)};
            });

            //console.log('002b - v KalkulaceTable - ks1', ks1);

            // Aktualizace ks2
            const ks2 = dataKalkulaceZdrojResNeakt.ks2.map(itemX => {
                detItemX = itemX;
                //console.log('Parsing JSON for itemX:', detItemX); // Vypíše itemX před pokusem o parsování
                try {
                    let pole1Data = JSON.parse(itemX.pole1Data);
                    pole1Data = pole1Data?.map(itemNeakt => {
                        const itemPolozka = kp.find(item => item.id === itemNeakt.kpId);
                        const itemSkupina1 = ks1.find(item => item.id === itemNeakt.ks1Id);
                        if (itemPolozka) {
                            const newItem = {};
                            for (let key in itemNeakt) {
                                const mappedKeyKp = keyMappingKp[key] ? keyMappingKp[key] : key;
                                newItem[key] = itemPolozka[mappedKeyKp] !== undefined ? itemPolozka[mappedKeyKp] : itemNeakt[key];
                                //newItem[key] = itemPolozka[mappedKeyKp] ? itemPolozka[mappedKeyKp] : itemNeakt[key];
                            }
                            if (itemSkupina1) {
                                for (let key in itemNeakt) {
                                    const mappedKeyKs1 = keyMappingKs1[key] ? keyMappingKs1[key] : key;
                                    newItem[key] = itemSkupina1[mappedKeyKs1] ? itemSkupina1[mappedKeyKs1] : newItem[key];
                                }
                            }
                            return newItem;
                        } else {
                            return itemNeakt;
                        }
                    });
                    return {...itemX, pole1Data: JSON.stringify(pole1Data)};
                } catch (error) {
                    console.error('Error parsing JSON for itemX:', detItemX);
                    console.error('Error message:', error.message);
                }
            });
            //console.log('002c - v KalkulaceTable - ks2', ks2);

            // Aktualizovaný objekt
            const dataKalkulaceZdrojRes = { kp, ks1, ks2 };
            console.log('002 - v KalkulaceTable - dataKalkulaceZdrojRes', dataKalkulaceZdrojRes);

            //---------------------------

            const dataKalkulaceZdrojKpRes = dataKalkulaceZdrojRes.kp.map(item => ({
                kpMnAdd: 0,
                ...item
                // Přidá nebo aktualizuje klíč kpMnAdd na hodnotu 0
            }));
            const dataKalkulaceZdrojKs1Res = dataKalkulaceZdrojRes.ks1.map(item => ({
                ks1MnAdd: 0,
                ...item
                // Přidá nebo aktualizuje klíč ks1MnAdd na hodnotu 0
            }));
            const dataKalkulaceZdrojKs2Res = dataKalkulaceZdrojRes.ks2.map(item => ({
                ks2MnAdd: 0,
                ...item
                // Přidá nebo aktualizuje klíč ks2MnAdd na hodnotu 0
            }));
        
            
            setDataKalkulaceZdroj(dataKalkulaceZdrojRes);
            setDataKalkulaceZdrojKp(dataKalkulaceZdrojKpRes);
            setDataKalkulaceZdrojKs1(dataKalkulaceZdrojKs1Res);
            setDataKalkulaceZdrojKs2(dataKalkulaceZdrojKs2Res);
    
        } catch (error) {
            console.error('Chyba při načítání dat:', error);
            console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
            
            if (error.message.includes('/logout') || error.message.includes('Invalid Token')) {
                window.location.href = '/logout';
            }
        }
        
    }, [aktualDetailSection, paramsL]); // Závislosti pro useCallback

    // zakladni nacteni po otevreni, pouzito jen v podskupine a skupine, kde chci automaticky aktualizaci vnitrnich dat
    useEffect(() => {
        if(detailDataNutne && dataKalkulaceZdrojKs1.length !== 0 && dataKalkulaceZdrojKs2.length !== 0 && !isTabChanged && tableEntita !== 'Kalkulace' && !appKalkulace && idUn && rowData?.pole1Data) {
            //neaktualizovana data polozky z db
            let dataNeakt = rowData?.pole1Data;
            if (dataNeakt !== null) {
                dataNeakt = JSON.parse(dataNeakt);
                                
            }
            console.log('0000000000 - v KalkulaceTable - dataNeakt', dataNeakt, idUn, tableEntita);
            console.log('0000000000 - v KalkulaceTable - dataKalkulaceZdrojKp', dataKalkulaceZdrojKp, idUn, tableEntita);
            console.log('0000000000 - v KalkulaceTable - dataKalkulaceZdrojKs1', dataKalkulaceZdrojKs1, idUn, tableEntita);

            //--------nacteny obsah z konkretni kalkulace ale po aktualizaci dat
            let initValue
            if(tableEntita === 'KalkulacniSkupina1') {
                console.log('0000000000 - v KalkulaceTable - dataKalkulaceZdrojKs1', dataKalkulaceZdrojKs1, idUn, tableEntita);
                
                const dataAkt = dataNeakt.map(itemNeakt => {
                    const itemPolozka = dataKalkulaceZdrojKp.find(item => item.id === itemNeakt.kpId);
                    if (itemPolozka) {
                      const newItem = {};
                      for (let key in itemNeakt) {
                        const mappedKey = keyMappingKp[key] ? keyMappingKp[key] : key;
                        newItem[key] = itemPolozka[mappedKey] !== undefined ? itemPolozka[mappedKey] : itemNeakt[key];
                        //newItem[key] = itemPolozka[mappedKey] ? itemPolozka[mappedKey] : itemNeakt[key];
                      }
                      return newItem;
                    } else {
                      return itemNeakt;
                    }
                });
                console.log('0000000000 - v KalkulaceTable - KalkulacniSkupina1 - dataAkt', dataAkt);
                if (dataAkt) {
                    //initValue = JSON.parse(dataAkt);
                    initValue = dataAkt;
                    setPole1Data(prevValues => ({
                        ...prevValues,
                        [idIdent]: initValue,
                    }));
                    
                    //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
                } else {
                    console.log('Item with given id not found');
                }
             
            }else if(tableEntita === 'KalkulacniSkupina2') {
                console.log('0000000000 - v KalkulaceTable - dataNeakt', dataNeakt, 'dataKalkulaceZdrojKp', dataKalkulaceZdrojKp, 'dataKalkulaceZdrojKs1', dataKalkulaceZdrojKs1);
                
                const dataAkt = dataNeakt.map(itemNeakt => {
                    const itemPolozka = dataKalkulaceZdrojKp.find(item => item.id === itemNeakt.kpId);
                    const itemSkupina1 = dataKalkulaceZdrojKs1.find(item => item.id === itemNeakt.ks1Id);
                    console.log('itemSkupina1', itemSkupina1)
                                        
                    let itemSkupina1Celek;
                    if(itemSkupina1 && itemSkupina1.pole1Data){
                        const itemSkupina1Data1 = JSON.parse(itemSkupina1.pole1Data);
                        //prochazim jednotlive polozky v poli pole1Data a k nim pridavam data z cele skupiny
                        itemSkupina1Celek = itemSkupina1Data1.map(item => {
                            const { pole1Data, ...rest } = item; // destructure pole1Data out
                            return {
                                ...rest,
                                ks1MnAdd: itemNeakt.ks1MnAdd // add ks1MnAdd from itemNeakt
                            };
                        });
                    }
                    console.log('itemSkupina1Celek', itemSkupina1Celek)
                                        
                    if (itemPolozka) {
                      const newItem = {};   
                      for (let key in itemNeakt) {
                        const mappedKeyKp = keyMappingKp[key] ? keyMappingKp[key] : key;
                        newItem[key] = itemPolozka[mappedKeyKp] !== undefined ? itemPolozka[mappedKeyKp] : itemNeakt[key];
                        //newItem[key] = itemPolozka[mappedKeyKp] ? itemPolozka[mappedKeyKp] : itemNeakt[key];
                      }
                      let newItemArray = [];
                      if (itemSkupina1Celek) {
                        itemSkupina1Celek.forEach(item => {
                            let newItemCopy = {...newItem, ...item};
                            newItemArray.push(newItemCopy);
                        });
                    }
                    return newItemArray.length > 0 ? newItemArray : newItem;
                    } else {
                      return itemNeakt;
                    }
                  });
                  // Flatten the array
                    const flatDataAkt = dataAkt.flat();
                    const uniqueDataAkt = flatDataAkt.filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t.kpId === item.kpId && t.ks1Id === item.ks1Id
                        ))
                    );
                console.log('0000000000 - v KalkulaceTable - KalkulacniSkupina2 - dataAkt', uniqueDataAkt);
                
                if (uniqueDataAkt) {
                    console.log('0000000000 - v KalkulaceTable - KalkulacniSkupina2 - dataAkt', uniqueDataAkt);
                    initValue = uniqueDataAkt;
                    setPole1Data(prevValues => ({
                        ...prevValues,
                        [idIdent]: initValue,
                    }));
                    //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
                } else {
                    console.log('Item with given id not found');
                }
                
            }
            spocitej(tableEntita, initValue)
                      
            console.log('0000000000 - v KalkulaceTable - initValue', initValue);
            //console.log('0000000000 - v KalkulaceTable - rowData', rowData);
            
            // ---------------------------- 
        }// zda některý z objektů v poli paramsL má vlastnost input rovnou 'pole1'
         
    }, [dataKalkulaceZdrojKs1, dataKalkulaceZdrojKs2]);

    // aktualizace dat v Kalkulaci, natahne aktualni stavy z KalkulacniPolozka, KalkulacniSkupina1, KalkulacniSkupina2
    //pozor zde dojde k nacteni radku z tabulky s hodnotami (dataNeakt - onRowClick1={refreshDat}), ktere do nich user zadal, tyto radky se zde aktualizuji do pole1Data
    //jen pro Kalkulaci
    const refreshDat = async (tableEntita, dataNeakt) => { 
        setIsChanged(true);
        setIsTabChanged(true);
        console.log('0 - v KalkulaceTable - refreshDat - dataNeakt', dataNeakt, 'tableEntita', tableEntita);


        const dataAkt = dataNeakt.map(itemNeakt => {
            let itemPolozka
            let itemSkupina1
            let itemSkupina2
            itemPolozka = dataKalkulaceZdrojKp.find(item => item.id === itemNeakt.kpId) || itemNeakt;
            //const itemSkupina1 = dataKalkulaceZdrojKs1.find(item => item.id === itemNeakt.ks1Id);
            if(itemNeakt.ks1Id){
            itemSkupina1 = dataKalkulaceZdrojKs1.find(item => item.id === itemNeakt.ks1Id) || null;
            } else { itemSkupina1 = null}
            if(itemNeakt.ks2Id){
            itemSkupina2 = dataKalkulaceZdrojKs2.find(item => item.id === itemNeakt.ks2Id) || null;
            } else { itemSkupina2 = null}
            
            console.log('1 - v KalkulaceTable refreshDat - aktualni stavy - itemPolozka', itemPolozka)
            console.log('1 - v KalkulaceTable refreshDat - aktualni stavy - itemSkupina1', itemSkupina1)
            console.log('1 - v KalkulaceTable refreshDat - aktualni stavy - itemSkupina2', itemSkupina2)
            //console.log('1 - v KalkulaceTable - dataKalkulaceZdrojKs2', dataKalkulaceZdrojKs2)

            let itemSkupina1Celek = [];
            if(itemSkupina1 && itemSkupina1.pole1Data){
                const itemSkupina1Data1 = JSON.parse(itemSkupina1.pole1Data);
                //prochazim jednotlive polozky v poli pole1Data a k nim pridavam data z cele skupiny
                itemSkupina1Celek = itemSkupina1Data1.map(item => {
                    const { pole1Data, ...rest } = item; // destructure pole1Data out
                    return {
                        ...rest,
                        ks1MnAdd: itemNeakt.ks1MnAdd, // add ks1MnAdd from itemNeakt
                       
                    };
                });
            }
            console.log('2 - v KalkulaceTable - itemSkupina1Celek', itemSkupina1Celek)

            let itemSkupina2Celek = [];
            if(itemSkupina2 && itemSkupina2.pole1Data){
                const itemSkupina1Data2 = JSON.parse(itemSkupina2.pole1Data);
                //console.log('itemNeakt', itemNeakt)
                //prochazim jednotlive polozky v poli pole1Data a k nim pridavam data z cele skupiny
                itemSkupina2Celek = itemSkupina1Data2.map(item => {
                    const { pole1Data, ...rest } = item; // destructure pole1Data out
                    return {
                        ...rest,
                        ks2MnAdd: itemNeakt.ks2MnAdd, // add ks1MnAdd from itemNeakt
                       
                    };
                });
            }
            console.log('2 - v KalkulaceTable - itemSkupina2Celek', itemSkupina2Celek)
            
            if (itemPolozka) {
              const newItem = {};   
              for (let key in itemNeakt) {
                const mappedKeyKp = keyMappingKp[key] ? keyMappingKp[key] : key;
                newItem[key] = itemPolozka[mappedKeyKp] !== undefined ? itemPolozka[mappedKeyKp] : itemNeakt[key];
              }
              let newItemArray = [];
              if (itemSkupina1Celek) {
                itemSkupina1Celek.forEach(item => {
                    let newItemCopy = {...newItem, ...item};
                    newItemArray.push(newItemCopy);
                })}
                if (itemSkupina2Celek) {
                    itemSkupina2Celek.forEach(item => {
                        let newItemCopy1 = {...newItem, ...item};
                        newItemArray.push(newItemCopy1);
                })};
                return newItemArray.length > 0 ? newItemArray : newItem;
            }
            
            
        });
        
        console.log('3 - v KalkulaceTable - dataAkt', dataAkt);
        // Flatten the array
        const flatDataAkt = dataAkt.flat();
        console.log('3 - v KalkulaceTable - flatDataAkt', flatDataAkt);
        const uniqueDataAkt = flatDataAkt.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.kpId === item.kpId && t.ks1Id === item.ks1Id && t.ks2Id === item.ks2Id && t.kpOblast === item.kpOblast && t.appIdObjednavka === item.appIdObjednavka && t.appIdRealizacniCinnost === item.appIdRealizacniCinnost && t.appIdServisZasah === item.appIdServisZasah && t.appIdSoD === item.appIdSoD && t.appIdSmlouvaServis === item.appIdSmlouvaServis && t.appIdZalohaFakturace === item.appIdZalohaFakturace && t.appIdReklamace === item.appIdReklamace && t.appIdStitek === item.appIdStitek
            ))
        );
        console.log('4 - v KalkulaceTable - uniqueDataAkt: ', uniqueDataAkt);
        
        if (uniqueDataAkt) {
                        
            setPole1Data(prevValues => ({
                ...prevValues,
                [idIdent]: uniqueDataAkt,
              }));
            
        } else {
            console.log('Item with given id not found');
        }

        try {
            setMessage(`Aktualizace dat proběhla úspěšně`);
            setSeverity('success');
        
            // Wait for 5 seconds before running spocitej
            await new Promise(resolve => setTimeout(resolve, 1000));
        
            // Clear the message
            setMessage(null);
        
            await spocitej(tableEntita, uniqueDataAkt)
        } catch (error) {
            console.log(error);
        }

           
    };

    // zakladni nacteni po otevreni, pouzito jen v Kalkulace, kde nechci automaticky aktualizaci vnitrnich dat
    //jen pro Kalkulaci
    useEffect(() => {
        if(detailDataNutne && paramsLAllAll && paramsL && paramsL?.some(param => param.input === 'pole1' && !isTabChanged && tableEntita === 'Kalkulace')) {

            //--------nacteny obsah z konkretni kalkulace
            let initValue = rowData?.pole1Data;
           
            //console.log('0000000000 - v KalkulaceTable - initValue', initValue);
            if (initValue !== null) {
                initValue = JSON.parse(initValue);
                //console.log('001 - v KalkulaceTable - initValue', initValue); 
                setPole1Data(prevValues => ({
                    ...prevValues,
                    [idIdent]: initValue,
                }));
                //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
            }

           
        }// zda některý z objektů v poli paramsL má vlastnost input rovnou 'pole1'
         
    }, [detailDataNutne]); // Prázdné závislosti znamenají, že tento efekt se spustí pouze jednou po prvním vykreslení

    useEffect(() => {
        if(tableEntita === 'Kalkulace') {
            console.log('0000000000A - v KalkulaceTable - inputDefaults[idVzorecL]', inputDefaults?.[idVzorecL], 'idVzorecL', idVzorecL,  'inputDefaults[idVzorecS]', inputDefaults?.[idVzorecS], 'inputDefaults', inputDefaults);
            console.log('0000000000B - v KalkulaceTable - idNakladyLead', idNakladyLead, 'idNakladySmlouva', idNakladySmlouva, 'dataNutne.dataGlob[0].vzorecL', dataNutne.dataGlob[0]?.vzorecL, 'dataNutne.dataGlob[0].vzorecS', dataNutne.dataGlob[0]?.vzorecS);

            let vzorecL = inputDefaults?.[idVzorecL] ? inputDefaults?.[idVzorecL] : dataNutne.dataGlob[0].vzorecL;
            let vzorecS = inputDefaults?.[idVzorecS] ? inputDefaults?.[idVzorecS] : dataNutne.dataGlob[0].vzorecS;
            setVzorce([{'idVysledek': idNakladyLead, 'vzorec': vzorecL}, {'idVysledek': idNakladySmlouva, 'vzorec': vzorecS}]);
            console.log('0000000000C - v KalkulaceTable - vzorecL', vzorecL, 'vzorecS', vzorecS);
        }
        
        
         
    }, [dataNutne, detailDataRest, inputLookups, inputDefaults]); // Prázdné závislosti znamenají, že tento efekt se spustí pouze jednou po prvním vykreslení

    // ------ zakladni nacteni po otevreni, pouzito jen v aplikaci Kalkulace do jinych elementu, ------- ale ne Objednavky a Reklamace ------------v detailSection Kalkulace ---------------------------------------------------------------
    //aplikace Kalkulace
    console.log('x0000000000 - v KalkulaceTable - rowDataAllKalkulace:', rowDataAllKalkulace, 'pole1DataInput.length:', pole1DataInput.length);
    useEffect(() => {
        if(rowDataAllKalkulace && aktualDetailSection === 'Kalkulace' && pole1DataInput.length === 0 && tableEntita !== 'Objednavka' && tableEntita !== 'Reklamace') {
            //console.log('v zakladnim nacteni pri aplikaci Kalkulace')
            //console.log('A0 - v zakladnim nacteni pri aplikaci Kalkulace do jine entity nez Objednavky - rowDataAllKalkulace', rowDataAllKalkulace)
            setInputBool(false);
            //--------nacteny obsah z konkretni kalkulace
/*
            const parovanaEntitaTable = {
                ServisZasah: 'Servis',
                RealizacniCinnost: 'OPB',
                Objednavka: 'Objednavka',
                Reklamace: 'Reklamace',
                OPB: 'OPB',
                Servis: 'Servis',
                SoD: 'OPB',
                ZalohaFakturace: 'OPB',
                OPS: 'OPS',
                Stitek: 'OPB',
                SmlouvaServis: 'OPB',
            }
*/
            let initValue = [];
            let porovnnavanaEntitaX
            const parovanaEntita = parovanaEntitaTable[tableEntita]; // pozor neni vyreseno pro RealizacniCinnost a SoD OPS
            if(parovanaEntita === tableEntita ) {
                porovnnavanaEntitaX = ''
            }else {
                porovnnavanaEntitaX = parovanaEntita
            }
            //console.log('A0 - v zakladnim nacteni pri aplikaci Kalkulace do jine entity nez Objednavky - parovanaEntita:', parovanaEntita, 'tableEntita:', tableEntita)
            rowDataAllKalkulace.forEach(item => {
                //console.log('A0 - v zakladnim nacteni pri aplikaci Kalkulace do jine entity nez Objednavky - item:', item, 'id + parovanaEntita:', 'id' + parovanaEntita, 'id+tableEntita:', item['id' + porovnnavanaEntitaX], 'idUn:', dataNutne.dataGlob[0]['id' + porovnnavanaEntitaX])
                if(item.aktivni){ //pokud je kalkulace aktivni
                                        
                    if(item['id' + parovanaEntita] == dataNutne.dataGlob[0]['id' + porovnnavanaEntitaX] && item.pole1Data) { // napr. tableEntita ZalohaFakturace, parovanaEntita OPB, v datech hleda idOPB
                        //console.log('A0 - v zakladnim nacteni pri aplikaci Kalkulace do jine entity nez Objednavky - item.pole1Data', item.pole1Data)
                        try {
                            let parsedData = JSON.parse(item.pole1Data);
                            parsedData.forEach(dataItem => {
                                dataItem.defIdKalkulace = item.id; // add new key-value pair
                                initValue.push(dataItem);
                            });
                        } catch(e) {
                            console.error("Error parsing JSON for item:", item, "Error:", e);
                        }
                    }
                }
                
            });
            //console.log('A0 - v zakladnim nacteni pri aplikaci Kalkulace do jine entity nez Objednavky - newData', initValue)
                        
            //let initValue = rowData?.pole1Data;
           
            //console.log('0000000000 - v KalkulaceTable - initValue', initValue);
            if (initValue !== null) {
                //initValue = JSON.parse(initValue);
                //let appFilter = initValue.filter(item => item[appParamId] === idUn);
                //console.log('001 - v KalkulaceTable - initValue s appFilter', appFilter); 
                setPole1Data(prevValues => ({
                    ...prevValues,
                    [idIdent]: initValue,
                })); //sem dam vsechny data
                //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
                setPole1DataAppView({[idIdent]: initValue}); //sem dam jen data pro konkretni aplikaci
                if(pole1Data){
                    spocitejAppKalkulace(tableEntita, initValue) 
                }
              
            }
           
        }
         
    }, [rowDataAllKalkulace, aktualDetailSection]); // Prázdné závislosti znamenají, že tento efekt se spustí pouze jednou po prvním vykreslení


    // ------ zakladni nacteni po otevreni, pouzito jen v aplikaci Kalkulace do --------- Ojednavky --------------v detailSection Kalkulace ---------------------------------------------------------------
    //aplikace Kalkulace
    //console.log('x0000000000 - v KalkulaceTable - isTabChanged', isTabChanged, 'rowData.pole1Data', rowData.pole1Data, 'rowData', rowData, 'idKalkulace', idKalkulace);
    useEffect(() => {
        if(rowDataAllKalkulace && aktualDetailSection === 'Kalkulace' && (tableEntita === 'Objednavka' || tableEntita === 'Reklamace') && pole1DataInput.length === 0) {  
            console.log('A1 - v zakladnim nacteni pri aplikaci Kalkulace do Objednavky - rowDataAllKalkulace', rowDataAllKalkulace)

            let initValue = [];
           
            const filterOPBPredSoD = ['00 Navolat', '01 Indikativní nabídka - poslat', '02 Indikativní nabídka -  poslána', '03 Finální nabídka -  poslána', '04 Smlouva - poslat k podpisu', '05 Smlouva - k podpisu poslána']
            const filterOPBPoSoD = ['06 Smlouva - podepsaná']
            rowDataAllKalkulace.forEach(item => {
                if(item.aktivni && item.pole1Data) { // jen aktivni kalkulace
                    try {
                        let parsedData = JSON.parse(item.pole1Data);
                        parsedData.forEach(dataItem => {
                            dataItem.defIdKalkulace = item.id;
                            dataItem.defIdOPB = item.idOPB;
                            dataItem.defProcesOPBObchod = item.procesOPBObchodOPB;
                            dataItem.defProcesOPBRealizace = item.procesOPBRealizaceOPB;
                            dataItem.defIdOPS = item.idOPS;
                            dataItem.defProcesOPSObchod = item.procesOPSObchodOPS;
                            dataItem.defProcesOPSRealizace = item.procesOPSRealizaceOPS;
                            dataItem.defIdServis = item.idServis;
                            dataItem.defProcesServis = item.procesServisServis;
                            initValue.push(dataItem);
                        });
                    } catch(e) {
                        console.error("Error parsing JSON for item:", item, "Error:", e);
                    }
                };

                
            });
            console.log('A1 - v zakladnim nacteni pri aplikaci Kalkulace do Objednavky - newData', initValue)
                                 
            //console.log('0000000000 - v KalkulaceTable - initValue', initValue);
            if (initValue !== null) {
                
                //let appFilter = initValue.filter(item => item[appParamId] === idUn);
                //console.log('A2 - v KalkulaceTable - initValue s appFilter', appFilter); 
                setPole1Data(prevValues => ({
                    ...prevValues,
                    [idIdent]: initValue,
                })); //sem dam vsechny data
                //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
                setPole1DataAppView({[idIdent]: initValue}); //sem dam jen data pro konkretni aplikaci
                if(pole1Data){
                    spocitejAppKalkulace(tableEntita, initValue) //pozor zde dojde k nacteni radku z tabulky s hodnotami, ktere do nuch user zadal, tyto radky se zde aktualizuji do pole1Data
                }
              
            }
            
           
        }
         
    }, [rowDataAllKalkulace, aktualDetailSection, inputLookups]); 
    
    
    //---------- pri zmene bool parametru inputBool ------------
    //aplikace Kalkulace
    //console.log('0000000000 - v KalkulaceTable - aktualDetailSection', aktualDetailSection, 'pole1Data', pole1Data, inputBool);
    useEffect(() => {
        console.log('0000000000 - v KalkulaceTable - pred if - aktualDetailSection', aktualDetailSection, 'pole1Data', pole1Data, inputBool);
        if(Object.keys(pole1Data).length > 0 && aktualDetailSection === 'Kalkulace' && tableEntita !== 'OPB' && tableEntita !== 'OPS' && tableEntita !== 'Servis') {
                        
            if(!inputBool){
                //console.log('0000000000 - v KalkulaceTable - inputBool je false - pole1Data', pole1Data);
                setPole1DataAppView(pole1Data); //nechavam zobrazit vse aktualne obsazene pro ulozeni v Kalkulaci
                   
                if(pole1Data){
                    spocitejAppKalkulace(tableEntita, pole1Data[idIdent])
                }
                
            }else{
                let appFilter = pole1Data[idIdent].filter(item => item[appParamId] === idUn);
                //console.log('0000000000 - v KalkulaceTable - appFilter', appFilter);
                //console.log('0000000000 - v KalkulaceTable - pole1Data', pole1Data);    
                                
                setPole1DataAppView({[idIdent]: appFilter});//nechavam zobrazit jen data pro konkretni aplikaci
                
                if(pole1Data){
                    spocitejAppKalkulace(tableEntita, appFilter)
                }
                
            }
  
        }// zda některý z objektů v poli paramsL má vlastnost input rovnou 'pole1'
         
    }, [inputBool]);
    

    //-------- specialne nactene parametry pro pouziti v Table Spocitej, ale jen v Kalkulace, protoze tyto parametry by se jinak dostali do pole inputRests až po zobrazeni Ekonomika, protoze tam se vyskytuji
    useEffect(() => {
        if(detailDataNutne && (!inputRests || !inputRests[2098]) && (!inputLookups || !inputLookups[2091]) && tableEntita === 'Kalkulace' && rowData && rowData.zakladniHrubaMarze && rowData.sazbaDph){
            //console.log('1 - v KalkulaceTable specialni nacteni zakladniHrubaMarze a sazbaDph - rowData', rowData);
            setInputRests(prevInputRests => ({
                ...prevInputRests,
                2098: Number(rowData.zakladniHrubaMarze),
                2091: Number(rowData.sazbaDph),
              }));
              /*
              setInputLookups(prevInputLookups => ({
                ...prevInputLookups,
                
              }));
              */
        }
    }, [detailDataNutne]);

   useEffect(() => {
    if(!isTabChanged){
        fetchDataKalkulaceZdroj();
    }
   }, [fetchDataKalkulaceZdroj, ]);
      
   const openNewWindow = (iconId) => {
    // místo otevření nového okna, otevřete modální dialog
    setModalIsOpen(true);
    setAddIconId(iconId);
    };

    let iconList = [];
    if(tableEntita === 'Kalkulace') {
        iconList = ['addKp', 'addKs1', 'addKs2'];
    } else if(tableEntita === 'KalkulacniSkupina2') {
        iconList = ['addKp', 'addKs1'];
    } else if(tableEntita === 'KalkulacniSkupina1') {
        iconList = ['addKp'];
    }
    //urceni co se pridava
    const stupenKalkulace = (iconId) => {
        //setIsChanged(true);
        if(iconId === 'addKp') {
            if (!page || !dataKalkulaceZdrojKp || !paramsLKalkulacniPolozkaBase0VizuPocet || !paramsLKalkulacniPolozkaBase0Pocet || !paramsLKalkulacniPolozkaBase0MjPocet || !paramsLKalkulacniPolozkaBase0FormatPocet) {
                return <LinearIndeterminate/>
            } else {
                return ({addIconTitle: 'Přidat Položku', addIcon: <AddBoxIcon sx={{ color: 'white', fontSize: '22px' }} />,
                addTable: <TableKalk tableEntita={'KalkulacePolozka'} pageP={page} data={dataKalkulaceZdrojKp} headers={paramsLKalkulacniPolozkaBase0VizuPocet}
                columnAccessors={paramsLKalkulacniPolozkaBase0Pocet} onRowClick={handleEntityClickHledat} mjs={paramsLKalkulacniPolozkaBase0MjPocet} inputFormat={paramsLKalkulacniPolozkaBase0FormatPocet} />});
            }  
        } else if(iconId === 'addKs1') {
            if (!page || !dataKalkulaceZdrojKs1 || !paramsLKalkulacniSkupina1Base0VizuPocet || !paramsLKalkulacniSkupina1Base0Pocet || !paramsLKalkulacniSkupina1BaseMj) {
                return <LinearIndeterminate/>
            } else {
                return ({addIconTitle: 'Přidat Podskupinu', addIcon: <LibraryAddIcon sx={{ color: 'white', fontSize: '22px' }} />,
                addTable: <TableKalk tableEntita={'KalkulaceSkupina1'} pageP={page} data={dataKalkulaceZdrojKs1} headers={paramsLKalkulacniSkupina1Base0VizuPocet}
                columnAccessors={paramsLKalkulacniSkupina1Base0Pocet} onRowClick={handleEntityClickHledat} mjs={paramsLKalkulacniSkupina1BaseMj} inputFormat={''} />});
            }
        } else if(iconId === 'addKs2') {
            if (!page || !dataKalkulaceZdrojKs2 || !paramsLKalkulacniSkupina2Base0VizuPocet || !paramsLKalkulacniSkupina2Base0Pocet || !paramsLKalkulacniSkupina2BaseMj) {
                return <LinearIndeterminate/>
            } else {
                return ({addIconTitle: 'Přidat Skupinu', addIcon: <LibraryBooksIcon sx={{ color: 'white', fontSize: '22px' }} />,
                addTable: <TableKalk tableEntita={'KalkulaceSkupina2'} pageP={page} data={dataKalkulaceZdrojKs2} headers={paramsLKalkulacniSkupina2Base0VizuPocet}
                columnAccessors={paramsLKalkulacniSkupina2Base0Pocet} onRowClick={handleEntityClickHledat} mjs={paramsLKalkulacniSkupina2BaseMj} inputFormat={''} />});
            }
        }
    }
    //pridani entity do tabulky
    //kazdy vybrany radek z tabulky je sem poslan extra a cely obsah fce se zpracuje vzdy pro jede radek, postupne jsou sem poslany vsechny radky
    const handleEntityClickHledat = async (tableEntita, id, index) => { 
        
        console.log('00 - v KalkulaceTable - handleEntityClick - tableEntita', tableEntita, 'id', id, 'index', index);
        //console.log('01 - v KalkulaceTable - handleEntityClick - pole1Data', pole1Data);   
        //console.log('v KalkulaceTable - handleEntityClick - prevzato', tableEntita, id, inputChanged, addIconId);
        console.log('00 - v KalkulaceTable - inputsAll', inputsAll);
        let dataAdd;
        let zakladniHrubaMarze = inputsAll[2098] || 0;
        let sazbaDph = inputsAll[2091] || 0;
        //varianta, kdy se přidává položka ----------------------------------------
        if(addIconId === 'addKp') { 
            const dataAddX = dataKalkulaceZdrojKp.filter(item => item.id === id); //najdu položku ve zdrojových datech podle id; toto nejsou jen zdrojova data, je zde pridano pole s poctem kpMnAdd: 0, ktere se vyplnuje ve vyberu
            const XX = dataAddX[0];
            //console.log('v KalkulaceTable - handleEntityClick - addKp - XX', XX);
            //console.log('v KalkulaceTable - handleEntityClick - addKp - columnAccessors', columnAccessors);
            dataAdd = columnAccessors.reduce((acc, key) => {
                let originalKey = key;
                if (key.startsWith('kp')) {
                    originalKey = key.charAt(2).toLowerCase() + key.slice(3); // transformuje klíč columnAccessors na formát kalkulační položky
                }
                if (XX.hasOwnProperty(originalKey)) {
                    acc[key] = XX[originalKey]; // přiřadí hodnotu z XX do nového objektu
                }
                if (XX.hasOwnProperty(key)) {
                    acc[key] = XX[key]; // přiřadí hodnotu z XX do nového objektu
                }
                if (key === 'kpMnAdd') {
                    acc[key] = Number(XX[key]); // převede hodnotu klíče 'kpMnAdd' na číslo
                  }
                //console.log('v KalkulaceTable - handleEntityClick - addKp - acc', acc);
                return acc;
            }, {});
            // Přidání výpočtu kpCenaCalc na konec
            /*
            if (dataAdd.hasOwnProperty('kpMnAdd') && dataAdd.hasOwnProperty('kpJc')) {
                dataAdd.kpMnCalc = dataAdd.kpMnAdd;
                dataAdd.kpCenaCalc = (dataAdd.kpMnCalc * dataAdd.kpJc) || 0;
                dataAdd.kpP31Calc = (dataAdd.kpMnCalc * dataAdd.kpP31) || 0;
            }
            */
            if (dataAdd.hasOwnProperty('kpMnAdd') && dataAdd.hasOwnProperty('kpJc')) {
                dataAdd.kpMnCalc = Number(dataAdd.kpMnAdd);
                dataAdd.kpCenaCalc = Math.round((dataAdd.kpMnCalc * dataAdd.kpJc)) || 0;
                dataAdd.kpP31Calc = (dataAdd.kpMnCalc * dataAdd.kpP31) || 0;
                
                dataAdd.kpMarzeProcento = dataAdd.kpIndivMarze + zakladniHrubaMarze;
                dataAdd.kpMarzeKc = Math.round(dataAdd.kpCenaCalc * (dataAdd.kpMarzeProcento/100)) || 0;
                dataAdd.kpCenaCelkemSMarzi = Math.round(dataAdd.kpCenaCalc + dataAdd.kpMarzeKc) || 0;
                dataAdd.kpCenaCelkemSMarziSDph = Math.round(dataAdd.kpCenaCelkemSMarzi * (1 + (sazbaDph || 0)/100)) || 0;
                
                //console.log('03a - v KalkulaceTable - handleEntityClick - addKp - item po vypoctu', dataAdd);
            }
            
            //z pole dat ziskanych z db a do kterych se pridalo pri vyberu mnozstvi do pole kpMnAdd se zde odstrani mnoszstvi, aby pri dalsim otevreni bylo pole prazdne
            if (index === 1){
                let newData = dataKalkulaceZdrojKp
                for (let row of newData) {
                    row.kpMnAdd = null;
                }
                setDataKalkulaceZdrojKp(newData);
                //console.log('111111111 - v KalkulaceTable - handleEntityClick - addKp - newData', newData);
            }
                 
            //varianta, kdy se přidává podskupina------------------------------------------------
        } else if(addIconId === 'addKs1') { 
            //console.log('02 - v KalkulaceTable - handleEntityClick - pole1Data', pole1Data);      
            const dataAddX = dataKalkulaceZdrojKs1.filter(item => item.id === id);
            const XXX = dataAddX[0];
            let pole1DataKs1 = JSON.parse(XXX.pole1Data);
            delete XXX.pole1Data;
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - pole1Data', pole1Data);
            let ks1Data = XXX;
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - ks1Data', ks1Data);
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - pole1DataKs1', pole1DataKs1);
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - columnAccessors', columnAccessors);
            
            const ks1DataTransf = columnAccessors.reduce((acc, key) => {
                let originalKey = key;
                if (key.startsWith('ks1')) {
                    originalKey = key.charAt(3).toLowerCase() + key.slice(4); // transformuje klíč zpět na původní formát
                }
                if (ks1Data.hasOwnProperty(originalKey)) {
                    acc[key] = ks1Data[originalKey]; // přiřadí hodnotu z XX do nového objektu
                }
                if (ks1Data.hasOwnProperty(key)) {
                    acc[key] = ks1Data[key]; // přiřadí hodnotu z XX do nového objektu
                }
                //console.log('v KalkulaceTable - handleEntityClick - addKs1 - acc', acc);
                return acc;
            }, {});
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - ks1DataTransf', ks1DataTransf);    
            
            const dataAddTemp = pole1DataKs1.map(item => {
                return {...ks1DataTransf, ...item};
            });
            //console.log('03 - v KalkulaceTable - handleEntityClick - pole1Data', pole1Data);
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - dataAdd', dataAdd);

            dataAdd = dataAddTemp.map(item => {
               
                if (item.hasOwnProperty('kpMnAdd') && item.hasOwnProperty('ks1MnAdd') && item.hasOwnProperty('kpJc') && item.hasOwnProperty('kpP31')){
                    item.kpMnCalc = Math.round((item.kpMnAdd * item.ks1MnAdd) * 10) / 10 || 0;
                    item.kpCenaCalc = Math.round((item.kpMnCalc * item.kpJc)) || 0;
                    item.kpP31Calc = (item.kpMnCalc * item.kpP31) || 0;
                    item.kpMarzeProcento = item.kpIndivMarze + zakladniHrubaMarze;
                    item.kpMarzeKc = Math.round(item.kpCenaCalc * (item.kpMarzeProcento/100)) || 0;
                    item.kpCenaCelkemSMarzi = Math.round(item.kpCenaCalc + item.kpMarzeKc) || 0;
                    item.kpCenaCelkemSMarziSDph = Math.round(item.kpCenaCelkemSMarzi * (1 + (sazbaDph || 0)/100)) || 0;
                    
                    //console.log('03a - v KalkulaceTable - handleEntityClick - addKs1 - item po vypoctu', item);
                }
                return item;
            });
            
            //z pole dat ziskanych z db a do kterych se pridalo pri vyberu mnozstvi do pole kpMnAdd se zde odstrani mnoszstvi, aby pri dalsim otevreni bylo pole prazdne
            if (index === 1){
                let newData = dataKalkulaceZdrojKs1
                for (let row of newData) {
                    row.ks1MnAdd = null;
                }
                setDataKalkulaceZdrojKs1(newData);
                //console.log('111111111 - v KalkulaceTable - handleEntityClick - addKp - newData', newData);
            }
            
        //varianta, kdy se přidává skupina------------------------------------------------
        } else if(addIconId === 'addKs2') { 
            const dataAddX = dataKalkulaceZdrojKs2.filter(item => item.id === id);
            const XXX = dataAddX[0];
            let pole1DataKs2 = JSON.parse(XXX.pole1Data);
            delete XXX.pole1Data;
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - pole1Data', pole1Data);
            let ks2Data = XXX;
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - ks1Data', ks2Data);
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - pole1DataKs1', pole1DataKs2);
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - columnAccessors', columnAccessors);
            const ks2DataTransf = columnAccessors.reduce((acc, key) => {
                let originalKey = key;
                if (key.startsWith('ks2')) {
                    originalKey = key.charAt(3).toLowerCase() + key.slice(4); // transformuje klíč zpět na původní formát
                }
                if (ks2Data.hasOwnProperty(originalKey)) {
                    acc[key] = ks2Data[originalKey]; // přiřadí hodnotu z XX do nového objektu
                }
                if (ks2Data.hasOwnProperty(key)) {
                    acc[key] = ks2Data[key]; // přiřadí hodnotu z XX do nového objektu
                }
                return acc;
            }, {});

            const dataAddTemp = pole1DataKs2.map(item => {
                return {...ks2DataTransf, ...item};
            });
            //console.log('03 - v KalkulaceTable - handleEntityClick - pole1Data', dataAddTemp);
            //console.log('v KalkulaceTable - handleEntityClick - addKs1 - dataAdd', dataAdd);

            dataAdd = dataAddTemp.map(item => {
                if (item.hasOwnProperty('kpMnAdd') && item.hasOwnProperty('ks1MnAdd') && item.hasOwnProperty('ks2MnAdd') && item.hasOwnProperty('kpJc')){
                    item.kpMnCalc = Math.round((item.kpMnAdd * item.ks1MnAdd * item.ks2MnAdd) * 10) / 10 || 0;
                    item.kpCenaCalc = Math.round((item.kpMnCalc * item.kpJc)) || 0;
                    item.kpP31Calc = (item.kpMnCalc * item.kpP31) || 0;
                    item.kpMarzeProcento = item.kpIndivMarze + zakladniHrubaMarze;
                    item.kpMarzeKc = Math.round(item.kpCenaCalc * (item.kpMarzeProcento/100)) || 0;
                    item.kpCenaCelkemSMarzi = Math.round(item.kpCenaCalc + item.kpMarzeKc) || 0;
                    item.kpCenaCelkemSMarziSDph = Math.round(item.kpCenaCelkemSMarzi * (1 + (sazbaDph || 0)/100)) || 0;
                    
                    //console.log('03a - v KalkulaceTable - handleEntityClick - addKs2 - item po vypoctu', item);
                }
                return item;
            });

            //z pole dat ziskanych z db a do kterych se pridalo pri vyberu mnozstvi do pole kpMnAdd se zde odstrani mnoszstvi, aby pri dalsim otevreni bylo pole prazdne
            if (index === 1){
                let newData = dataKalkulaceZdrojKs2
                for (let row of newData) {
                    row.ks2MnAdd = null;
                }
                setDataKalkulaceZdrojKs2(newData);
                //console.log('111111111 - v KalkulaceTable - handleEntityClick - addKp - newData', newData);
            }

        }
        //console.log('v KalkulaceTable - handleEntityClick - dataAdd', dataAdd);
        //console.log('04 - v KalkulaceTable - handleEntityClick - pole1Data', pole1Data);
               
        try {
            
            //console.log('0555555 - v KalkulaceTable - handleEntityClick - pole1Data', pole1Data, 'idIdent', idIdent);
            setPole1Data(prevValues => {
                // Získejte pole objektů
                let array = prevValues[idIdent] || [];
                console.log('66666 - v KalkulaceTable - handleEntityClick - array pred Add', array);

                // Přidejte nový objekt do pole
                if (dataAdd) {
                    if (Array.isArray(dataAdd)) {
                        array = [...array, ...dataAdd];
                    } else {
                        array.push(dataAdd);
                    }
                    //console.log('7777777 - v KalkulaceTable - handleEntityClick - array po Add', array);
                }
                
                 // Aktualizujte objekt s novým polem pro daný řetězcový identifikátor
                const updatedValues = { ...prevValues, [idIdent]: array };
                //console.log('8888888 - v KalkulaceTable - handleEntityClick - updatedValues', updatedValues);

                // Vraťte aktualizované hodnoty
                return updatedValues;
            });
            //console.log('999999 - v KalkulaceTable - handleEntityClick - pole1Data', pole1Data);
                        
            setIsChanged(true);
            setIsTabChanged(true);
            setInputChanged(true); 
            //console.log('v LookupComponent - handleEntityClick - inputLookups', inputLookups, 'lookupList :', lookupList)
            setModalIsOpen(false); // Zavře modální okno
            setAddIconId(null);
            
            
        } catch (error) {
            console.error('Chyba při zmene default:', error);
        }
 
    };
   //console.log('06 - v KalkulaceTable - pole1DataInput', pole1DataInput, 'headers', headers, 'columnAccessors', columnAccessors, 'page', page, 'tableEntita', tableEntita);

    const spocitejAppKalkulace = async (tableEntita, dataTable) => { 
        console.log('00 - v KalkulaceTable - spocitej - rowData', rowData);
                
        //console.log('0 - v KalkulaceTable - spocitej - tableEntita', tableEntita, 'dataTable', dataTable);
        //console.log('0 - v KalkulaceTable - spocitej - inputsAll', inputsAll);
        //console.log('0 - v KalkulaceTable - spocitej - inputRests', inputRests);
        
        let cenaCelkemBezDph = 0;
        let cenaCelkemSDph = 0;
        let hrubaMarze = 0;
        let cistaMarze = 0;
        let zakazkoveHodiny = 0;
        let nakladyNePrace = 0;
        let nakladyPrace = 0;
                    
                
        if (Array.isArray(dataTable)) {
            dataTable.forEach((data, index) => {
                //console.log('1 - v KalkulaceTable - spocitej - data', data, 'index', index);
                
                let dataNew = data;
                cenaCelkemBezDph += dataNew.kpCenaCelkemSMarzi;
                cenaCelkemSDph += dataNew.kpCenaCelkemSMarziSDph || 0;
                cistaMarze += dataNew.kpMarzeKc;
                zakazkoveHodiny += dataNew.kpP0 === 'Práce' ? dataNew.kpMnCalc : 0;
                nakladyNePrace += dataNew.kpP0 === 'NEPráce' ? dataNew.kpCenaCalc : 0;
                nakladyPrace += dataNew.kpP0 === 'Práce' ? dataNew.kpCenaCalc : 0;
                hrubaMarze += cistaMarze + nakladyPrace;        
               
                //console.log('1a - v KalkulaceTable - spocitej - po vypoctu - dataNew', dataNew, 'nakladovePrace', nakladyPrace, 'nakladyNePrace', nakladyNePrace, 'zakazkoveHodiny', zakazkoveHodiny, 'hrubaMarze', hrubaMarze);
                dataTable[index] = dataNew; 
                
                
            })
        }
        
        
        const hrubaMarzeNaZH = Math.round(hrubaMarze / zakazkoveHodiny);
        const cistaMarzeNaZH = Math.round(cistaMarze / zakazkoveHodiny);
        console.log('puvodni pole1Data', pole1Data)
        /*
        setPole1Data(prevValues => {

            // Zkontrolujte, zda dataTable obsahuje nějaké změny
            const hasChanges = dataTable.some((item, index) => item !== prevValues[idIdent][index]);
          
            if (hasChanges) {
              // Pokud jsou nějaké změny, vrátíme nový objekt s aktualizovanými hodnotami
              return {
                ...prevValues,
                [idIdent]: dataTable,
              };
            } else {
              // Pokud nejsou žádné změny, vrátíme původní objekt bez změn
              return prevValues;
            }

        });
        console.log('2 - v KalkulaceTable - spocitej - pole1Data - dataTable', dataTable);
        */
        
        if(appKalkulace) {
            
            setInputRests(prevInputRests => ({
                ...prevInputRests,
                [idCenaCelkemBezDph]: Math.round(cenaCelkemBezDph),
                [idCenaCelkemSDph]: Math.round(cenaCelkemSDph),
                [idZakazkoveHodiny]: Math.round(zakazkoveHodiny),
                [idNakladyPrace]: Math.round(nakladyPrace),
                [idNakladyNePrace]: Math.round(nakladyNePrace),
                
            }));
        }
        
        

    }

    const vymazRadky = async (tableEntita, dataTableAfterRemove) => {
        console.log('pole1Data pred promazanim', pole1Data, idIdent);
        console.log('vymaz tyto radky - tableEntita', tableEntita, 'dataTableAfterRemove', dataTableAfterRemove);
        
    
        setPole1Data(prevState => ({ ...prevState, [idIdent]: dataTableAfterRemove }));
        //console.log('x1 - v KalkulaceTable - po setPole1Data - dataTableAfterRemove', dataTableAfterRemove);
        
        try {
            setMessage(`Vybrané řádky byly úspěšně smazány`);
            setSeverity('success');
        
            // Wait for 5 seconds before running spocitej
            await new Promise(resolve => setTimeout(resolve, 2000));
        
            // Clear the message
            setMessage(null);
        
            await spocitej(tableEntita, dataTableAfterRemove)
        } catch (error) {
            console.log(error);
        }

    };

   
    //pozor zde dojde k nacteni radku z tabulky s hodnotami, ktere do nich user zadal, tyto radky se zde aktualizuji do pole1Data
    const spocitej = async (tableEntita, dataTable) => { 
        /*
        if(tableEntita !== 'Kalkulace') {
            return;
        }
        */
        console.log('00 - v KalkulaceTable - spocitej - tableEntita', tableEntita, 'dataTable', dataTable, rowData.zakladniHrubaMarze, rowData.sazbaDph);
        console.log('00 - v KalkulaceTable - spocitej - rowData', rowData);
        //neprovede se pokud jsem v applikaci Kalkulace do entity
        /*
        if (appKalkulace) {
            return;
        }
        */
        setIsChanged(true);
        setIsTabChanged(true);
        //console.log('0 - v KalkulaceTable - spocitej - tableEntita', tableEntita, 'dataTable', dataTable);
        //console.log('0 - v KalkulaceTable - spocitej - inputsAll', inputsAll);
        //console.log('0 - v KalkulaceTable - spocitej - inputRests', inputRests);
        
        let cenaCelkemBezDph = 0;
        let hrubaMarze = 0;
        let cistaMarze = 0;
        let zakazkoveHodiny = 0;
        let nakladyNePrace = 0;
        let nakladyPrace = 0;
        let zakladniHrubaMarze = inputsAll[2098] || 0;
        let sazbaDph = inputsAll[2091] || 0;
        let vykonFve = 0;
        let vykonTc = 0;
        let kapacitaBat = 0;
        let pocetPanelu = 0;
        let nakladyLead = 0;
        let nakladySmlouva = 0;               
                
        if (Array.isArray(dataTable)) {
            dataTable.forEach((data, index) => {
                //console.log('1 - v KalkulaceTable - spocitej - data', data, 'index', index);
                
                let dataNew = data;
                
                if (dataNew.hasOwnProperty('kpMnAdd') && dataNew.hasOwnProperty('kpJc')) {
                    dataNew.kpMnCalc = Math.round((dataNew.kpMnAdd * (dataNew.ks1MnAdd ? dataNew.ks1MnAdd : 1) * (dataNew.ks2MnAdd ? dataNew.ks2MnAdd : 1)) * 100) / 100 || 0;
                    dataNew.kpCenaCalc = Math.round(dataNew.kpMnCalc * dataNew.kpJc) || 0;
                    dataNew.kpMarzeProcento = dataNew.kpIndivMarze + zakladniHrubaMarze;
                    dataNew.kpMarzeKc = dataNew.kpCenaCalc * (dataNew.kpMarzeProcento/100) || 0;
                    dataNew.kpCenaCelkemSMarzi = Math.round(dataNew.kpCenaCalc + dataNew.kpMarzeKc) || 0;
                    dataNew.kpCenaCelkemSMarziSDph = Math.round(dataNew.kpCenaCelkemSMarzi * (1 + (sazbaDph / 100))) || 0;
                    dataNew.kpP31Calc = (dataNew.kpMnCalc * dataNew.kpP31) || 0;
                    cenaCelkemBezDph += dataNew.kpCenaCelkemSMarzi;
                    cistaMarze += dataNew.kpMarzeKc;
                    zakazkoveHodiny += dataNew.kpP0 === 'Práce' ? dataNew.kpMnCalc : 0;
                    nakladyNePrace += dataNew.kpP0 === 'NEPráce' ? dataNew.kpCenaCalc : 0;
                    nakladyPrace += dataNew.kpP0 === 'Práce' ? dataNew.kpCenaCalc : 0;
                    vykonFve += dataNew.kpP4 === 'FV panel' ? dataNew.kpP31Calc : 0;
                    vykonTc += dataNew.kpP4 === 'VEJ' ? dataNew.kpP31Calc : 0;
                    kapacitaBat += dataNew.kpP4 === 'Baterie' ? dataNew.kpP31Calc : 0;
                    pocetPanelu += dataNew.kpP4 === 'FV panel' ? dataNew.kpMnCalc : 0;
                                    
                }
                //console.log('1a - v KalkulaceTable - spocitej - po vypoctu - dataNew', dataNew, 'nakladovePrace', nakladyPrace, 'nakladyNePrace', nakladyNePrace, 'zakazkoveHodiny', zakazkoveHodiny, 'hrubaMarze', hrubaMarze);
                dataTable[index] = dataNew; 
                
                
            })
        }
        
        const cenaCelkemSDph = Math.round(cenaCelkemBezDph * (1 + (sazbaDph / 100)));
        hrubaMarze = cistaMarze + nakladyPrace;
        
        setPole1Data(prevValues => ({
            ...prevValues,
            [idIdent]: dataTable,
        }));
        
        //console.log('x1 - v KalkulaceTable - po setPole1Data', dataTable);
        console.log('2 - v KalkulaceTable - spocitej - pole1DataInput', pole1DataInput, 'cenaCelkemBezDph', cenaCelkemBezDph, 'cenaCelkemSDph', cenaCelkemSDph, 'nakladyPrace', nakladyPrace, 'nakladyNePrace', nakladyNePrace, 'zakazkoveHodiny', zakazkoveHodiny, 'hrubaMarze', hrubaMarze);
        
        //parametry jen Kalkulace
        if(tableEntita === 'Kalkulace') {
            setInputRests(prevInputRests => ({
                ...prevInputRests,
                [idCenaCelkemBezDph]: Math.round(cenaCelkemBezDph),
                [idCenaCelkemSDph]: Math.round(cenaCelkemSDph),
                [idZakazkoveHodiny]: Math.round(zakazkoveHodiny),
                [idNakladyPrace]: Math.round(nakladyPrace),
                [idNakladyNePrace]: Math.round(nakladyNePrace),
                [idVykonFve]: vykonFve,
                [idVykonTc]:  vykonTc,
                [idKapacitaBat]: kapacitaBat,
                [idPocetPanelu]: pocetPanelu,

            }));
        }
/*
        if(appKalkulace) {
            
            setInputRests(prevInputRests => ({
                ...prevInputRests,
                [idCenaCelkemBezDph]: Math.round(cenaCelkemBezDph),
                [idZakazkoveHodiny]: Math.round(zakazkoveHodiny),
                
            }));
        }
        */

        //---- pocitani podle vzorcu vlozenych do promennych------------------------------------------------
        //console.log('3 - v KalkulaceTable - spocitej - inputDefaults', inputDefaults);
        //console.log('3 - v KalkulaceTable - spocitej - inputRests', inputRests);
        
        //console.log('vzorce', vzorce); // const vzorce definovana v uvodu kodu, pr. const vzorce = [{'idVysledek': idNakladyLead, 'vzorec': inputDefaults[idVzorecL]}, {'idVysledek': idNakladySmlouva, 'vzorec': inputDefaults[idVzorecS]}];
        if(tableEntita === 'Kalkulace'){
            if (vzorce.length > 0) {
                vzorce.forEach((item) => {
                    //console.log('item', item); //{idVysledek: 2116, vzorec: 'p1S * TGH ( ( cenaCelkemBezDph ) / 100000 / p2S ) + fixS'}
                    if(item.vzorec) { // v pripade ze je item null, neprovede se
                        let parts = item.vzorec.split(/\s+/); // pozor poznava podle mezer
                        //console.log('parts', parts); // ['p1L', '*', 'TGH', '(', '(', 'cenaCelkemBezDph', ')', '/', '100000', '/', 'p2L', ')', '+', 'fixL']
                    
                        for (let i = 0; i < parts.length; i++) { // prochazi kazdou polozku
                            const part = parts[i];
                            if (!['+', '-', '*', '/'].includes(part)) {
                                //console.log('Before replacement:', parts);
                                // nahradi TGH za Math.tanh, to je standardni matematicka funkce javascriptu
                                if (part === 'TGH') {
                                    parts[i] = 'Math.tanh';
                                }else{
                                    //console.log('After replacement:', parts);
                                    //console.log('paramsLAllAll', paramsLAllAll )
                                    //console.log('part', part)
                                    const paramItem = paramsLAllAll.find(item => item.parametr === part);
                                    const paramDruh = paramItem?.input;
                                    const zdroj = paramItem?.zdroj;
                                    //console.log('paramItem', paramItem);
                                    // vyhleda hodnotu parametru v inputDefaults nebo inputRests 
                                    if (paramItem) {
                                        const id = paramItem.id;
                                        const parametr = paramItem.parametr;
                                        if(paramDruh === 'auto') { // v tomto pripade nevezme hodnotu z inputDefaults nebo inputRests, ale z konst vypocitanych zde ve funkci
                                            parts[i] = isNaN(eval(paramItem.parametr)) ? 0 : eval(paramItem.parametr); //eval vyhodnotí řetězec jako kód JavaScriptu, napr cenaCelkemBezDph
                                        }else if(paramDruh === 'default') {
                                            if (inputDefaults[id] !== undefined) { // nutne kdyz user aktualne dosadi hodnotu do inputu ktera ma vliv na default hodnotu potom to neni v baliku dat pro dany deteail, ale v inputDefaults
                                                //console.log('existuje inputDefaults[id]', inputDefaults[id]);
                                                parts[i] = isNaN(inputDefaults[id]) ? 0 : inputDefaults[id];
                                                
                                            }else{ // kdyz user jeste nezobrazil detailSection kde se parametr nachazi, hodnota nebude v poli inputDefaults, proto si beru data primo z baliku dat pro dany deteail
                                                console.log('neexistuje inputDefaults[id], proto dosazuji z dataNutne.dataGlob[0][parametr] ', dataNutne.dataGlob[0][parametr], 'inputDefaults[id] je:', inputDefaults[id]);
                                                parts[i] = isNaN(dataNutne.dataGlob[0][parametr]) ? 0 : dataNutne.dataGlob[0][parametr];
                                            
                                            }
                                        }else{
                                            if (inputRests[id] !== undefined) {
                                                parts[i] = isNaN(inputRests[id]) ? 0 : inputRests[id];
                                            }else{
                                                parts[i] = isNaN(dataNutne.dataGlob[0][parametr]) ? 0 : dataNutne.dataGlob[0][parametr];
                                            }
                                           
                                        }
                                        
                                        //console.log('parts[i]', parts[i]);
                                    }
                                }
                                
                            } 
                                
                            
                        }
    
                        // Spojujte části zpět do jednoho řetězce a vypočítejte výsledek
                        for (let i = 0; i < parts.length; i++) {
                            if (!isNaN(parts[i])) {
                              parts[i] = parseFloat(parts[i]);
                            }
                        }
                        //console.log('parts', parts); //[180000, '*', 'Math.tanh', '(', '(', 382111, ')', '/', 100000, '/', 100, ')', '+', 3000]
                        const calcValue = eval(parts.join(' '));
                        //console.log('calcValue', calcValue)
                        //console.log(' item.idVysledek: Math.round(calcValue)', item.idVysledek, Math.round(calcValue))
                            
                        setInputRests(prevInputRests => ({
                            ...prevInputRests,
                            [item.idVysledek]: Math.round(calcValue),
                        }));
                    }
                });
            }
            //----------------------------------------------------------
    
            // dopocitani marzi odectenim nakladu na lead a smlouvu
            nakladyLead = inputRests?.[idNakladyLead] || 0;
            nakladySmlouva = inputRests?.[idNakladySmlouva] || 0;
            cistaMarze -= nakladyLead + nakladySmlouva;
            hrubaMarze -= nakladyLead + nakladySmlouva;
            const hrubaMarzeNaZH = Math.round(hrubaMarze / zakazkoveHodiny);
            const cistaMarzeNaZH = Math.round(cistaMarze / zakazkoveHodiny);
            setInputRests(prevInputRests => ({
                ...prevInputRests,
                [idHrubaMarze]: Math.round((hrubaMarze)),
                [idCistaMarze]: Math.round(cistaMarze),
                [idHrubaMarzeNaZH]: Math.round(hrubaMarzeNaZH),
                [idCistaMarzeNaZH]: Math.round(cistaMarzeNaZH),
                
            }));
        }
        
        
        //console.log('4 - v KalkulaceTable - spocitej - message', message);
        if(message === null) {
            setMessage(`Přepočítání proběhlo úspěšně`);
            setSeverity('success');
            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessage(null);
            }, 1000);
        }
        
        if (aktualDetailSection !== 'List'){
            onCalculationDone(); // zavolam funkci v DetailView, ktera zajisti ze se nevyrendruje KalkulaceTable
        }
        
   
    }

    const handleInputChange = (event) => {
        let rawValue = event.target.checked;
        setInputBool(rawValue);
        setIsTabChanged(true);
          
    };
    
        

    // podminka (!pole1DataInput && rowData.pole1Data) je zde kvuli tomu, ze na pole1DataInput se bude cekat jen, kdyz rowData.pole1Data existuje; ziskani !pole1DataInput je u Skupiny a Podskupiny delsi kvuli aut aktualizaci dat 
    //console.log('0 - v KalkulaceTable - pole1DataInput', pole1DataInput, 'rowData.pole1Data', rowData.pole1Data);
    if (!dataNutne || !headers || !columnAccessors || (!pole1DataInput && rowData?.pole1Data && rowDataAllKalkulace)) {
        return <LinearIndeterminate/>
    } else {
        return (
            <div>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '220px',
                    }}
                >
                    {iconList.map((iconId, index) => (
                        <Box 
                        key={index}    
                        sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                key={iconId}
                                onClick={() => openNewWindow(iconId)}
                                title={stupenKalkulace(iconId).addIconTitle}
                                sx={{ 
                                    margin: '15px',
                                    backgroundColor: theme.palette.secondary.dark,
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary.main,
                                    },
                                }}
                            >
                                {stupenKalkulace(iconId).addIcon}                                
                            </IconButton>
                            
                        </Box>
                    ))}
                    
                </Box>
                    <Modal
                        iconId={addIconId}
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        contentLabel="Hledat Modal"
                        style={{
                            content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%', // Nastavení šířky na 80% obrazovky
                            height: '80%', // Nastavení výšky na 80% obrazovky
                            }
                        }}
                    >
                        {stupenKalkulace(addIconId)?.addTable}
                                                
                    </Modal>
                    { message && (
                        <Alert variant="outlined" severity={severity} style={{ position: 'fixed', top: 280, left: `50%`,
                        transform: 'translate(-50%, -0%)', zIndex: 9999, height: 50, width: 350, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            {message}
                        </Alert>
                )}
                <TableKalk1 idIdent={idIdent} key={pole1DataInput.length} tableEntita={tableEntita} pageP={page} data={pole1DataInput} headers={headers} columnAccessors={columnAccessors}
                 onRowClick={spocitej} onRowClick1={refreshDat} onRowClick2={vymazRadky} mjs={mjs} inputFormat={inputFormat} inputBool={inputBool} handleInputChange={handleInputChange}/>
                 
            </div>
            
                
        );
    }

      
};

