//LookupComponent.jsx
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailDataContext } from '../store/detailDataContext';
import HledatComponent from './HledatComponent'; // importujte HledatComponent
import { dataInputDefault1 } from '../api/dataInputDefault1';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { ArrowDropDown } from '@mui/icons-material'; 
import { DataContext } from '../store/baseData';
import { Link as LinkIcon } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';

// Toto je nutné pro přístupnost (a11y)
Modal.setAppElement('#root');

const LookupComponent = ({ param, placementRef }) => {
    // Přiřazení hodnoty inputu na základě dostupných dat
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate(); // Inicializace useNavigate
        

    const openNewWindow = () => {
        // místo otevření nového okna, otevřete modální dialog
        setModalIsOpen(true);
    };

    const {
        inputLookups,
        setInputLookups,
        inputDefaults,
        setInputDefaults,
        inputChanged,
        setInputChanged,
        detailDataNutne,
        detailDataRest,
        isChanged,
        setIsChanged
    } = useContext(DetailDataContext); // Získání hodnot z kontextu
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, isHeaderMenuVisible, setIsHeaderMenuVisible } = useContext(DataContext);

    //const [inputValue, setInputValue] = useState(inputLookups?.[param.id] || '');
    //console.log('11111111111 v LookupComponent - inputValue', inputValue, inputLookups, param.id);
    //useEffect(() => {
    //    setInputValue(inputLookups?.[param.id] || '');
    //}, [inputLookups, param.id, param, placementRef]);
    
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne.headers; // Získání dat z detailDataContext
    const dataDefaultGlob = detailDataRest?.dataDefaultGlob; // Získání dat z detailDataContext
    const zdrojPar = param.zdrojParametr;
    const zdrojParRidicihoPar = param.zdrojParRidicihoPar;
    const header = param.parametr;
    const isId = header.substring(0, 2) === 'id'; // Zjistí, zda je parametr typu id, kontrluja prvni dva znaky
    const paramsDefaultGlob = detailDataRest?.paramsDefaultGlob;
    const zdroj = param.zdroj;
    const unit = param.jednotka;
    const ridiciParametr = param.ridiciParametr;
    const idRidiciParametr = paramsL.find(item => item.parametr === ridiciParametr)?.id;  

    const paramArray = Object.entries(param).map(([key, value]) => ({ key, value }));

    const inputLookup = inputLookups?.[param.id] || '';
    //console.log('v LookupComponent - inputLookup', inputLookup, inputLookups);
    const [druh, setDruh] = useState(rowData[header] || '');
    
    const [lookupList, setLookupList] = useState(ciselnikGlob.filter(item => item.parametr === param.parametr)?.map(item => item.value));
    
    //console.log('v LookupComponent - placementRef', placementRef);
    //console.log('v LookupComponent - paramArray', paramArray, 'param', param, 'param.zdrojParametr', param.zdrojParametr, 'param.ridiciParametr', param.ridiciParametr);
    //console.log('v LookupComponent - dataDefaultGlob', dataDefaultGlob.default, zdrojParRidicihoPar, zdrojPar);
    
    useEffect(() => {
        if (detailDataNutne) {


           //console.log('000000 - v LookupCiselnikComponent - useEffect: ', 'header:', header, 'zdrojPar:', zdrojPar, 'zdrojParRidicihoPar:', zdrojParRidicihoPar, 'isId:', isId);
           //console.log('v LookupComponent - param', param);
           let ridiciParametrValue

            if (inputLookups && inputLookups[idRidiciParametr]) {
                ridiciParametrValue = inputLookups[idRidiciParametr];
                //console.log('v LookupComponent - mam data v inputLookups - ridiciParametrValue:', ridiciParametrValue);
            } else {
                ridiciParametrValue = rowData[ridiciParametr];
                //console.log('v LookupComponent - nemam data v inputLookups - ridiciParametrValue:', ridiciParametrValue);
            }
            //console.log('v LookupComponent - ridiciParametrValue', ridiciParametrValue);            

            const zavislyParametrCiselniku = paramArray.filter(item => item.ridiciParametr === header && item.zdroj === '_ciselnik');
            let druhRes = '';
            if (param.zdroj == '_ciselnik' && ridiciParametr) {
                druhRes = 'zavisly'
                //console.log(header, ' je parametr vybirany z ciselniku podle zvoleneho jineho parametru')
                
            } else if (zavislyParametrCiselniku.length > 0) {
                druhRes = 'ridici'
                //console.log(header, ' je parametr ridici pro jiny parametr vybirany z ciselniku')
                //console.log('rizeny parametr je:', zavislyParametrCiselniku)
                
            }
            setDruh(druhRes); // aktualizace stavu

            //console.log('v LookupCiselnikComponent - param.zdroj :', param.zdroj, 'param.parametr: ', param.parametr, 'ridiciParametr :', ridiciParametr, 'ridiciParametrValue :', ridiciParametrValue, 'ciselnikGlob: ', ciselnikGlob );
           
                       
            if (lookupList && !inputLookup && param && param.parametr && rowData && rowData[param.parametr] && !inputChanged) {
                //console.log('v LookupComponent - splnena podminka pro vlozeni hodnoty do inputu', param.id, rowData[param.parametr], inputChanged, isChanged);
                setInputLookups(prevValues => ({
                    ...prevValues,
                    [param.id]: rowData[param.parametr],
                }));
            }
            setIsChanged(true);
            //setInputChanged(false);
            console.log('v LookupComponent - useEffect- inputLookups', inputLookups, inputChanged, isChanged);
        }
        
  
    }, [param, placementRef, detailDataNutne, idRidiciParametr, inputLookups, inputDefaults]);
    
    
    const handleEntityClick = async (val, id) => {
        
        console.log('v LookupComponent - handleEntityClick - prevzato', val, id, inputChanged);
        //console.log('v LookupComponent - handleEntityClick - param', param.id, param.parametr, param.vizu);
        const newInputValue = val;
        
               
        try {
            setInputLookups(prevValues => ({
                ...prevValues,
                [param.id]: newInputValue,
            }));
            
            //console.log('v LookupComponent - handleEntityClick - param', param);
            //console.log('v LookupComponent - handleEntityClick - inputDefaults', inputDefaults);
            //const inputDefs = dataInputDefault1(header, newInputId, paramsDefaultGlob, dataDefaultGlob, paramsL, zdroj);
            const inputDefs = dataInputDefault1(header, id, paramsDefaultGlob, dataDefaultGlob, paramsL, zdroj, val);
            console.log('v LookupComponent - handleEntityClick - inputDefs', inputDefs)
           
            
            for (let [id, inputDef] of Object.entries(inputDefs)) {
                setInputDefaults(prevValues => {
                    const newValues = {
                        ...prevValues,
                        [id]: inputDef,
                    };
                    //console.log('v LookupComponent - handleEntityClick - zmenen default :', newValues, inputChanged);
                    return newValues;
                });
            }
            setIsChanged(true);
            setInputChanged(true); 
            //console.log('v LookupComponent - handleEntityClick - inputLookups', inputLookups, 'lookupList :', lookupList)
            setModalIsOpen(false); // Zavře modální okno
            //console.log('v LookupComponent - handleEntityClick - inputLookups', inputLookups, inputChanged, isChanged);
            
            
        } catch (error) {
            console.error('Chyba při zmene default:', error);
        }
 
    };

    const handleEntityEdit = (val) => {
        
        setInputChanged(true);               
        //console.log('v handleEntityEdit - zmeneno klavesnici: ', val);
        handleEntityClick(val, val); // odstraneni input

            
    };


// A pak ve vašem JSX použijte `linkContent` místo `value`

   
    
    if (!detailDataNutne){
        return <div>Loading...</div>;
    }else{
        return (
            <Box 
                component="form"
                sx={{
                    display: 'grid',
                    placeItems: 'center',
                    '& .MuiTextField-root': { m: 1 },
                    margin: '10px 0 0px 0',
                }}
                noValidate
                autoComplete="off"
            >
            <FormControl>
    
            <TextField
            id={`input_${header}`}
            value={inputLookup}
            label={param.vizu}
            onChange={(e) => handleEntityEdit(e.target.value)} // Aktualizace stavu při změně inputu
            
            sx={{ 
                backgroundColor: druh === 'zavisly' ? '#ccc' : druh === 'ridici' ? '#bfbfbf' : 'inherit',
                width: '300px',
                margin: '0px 0 10px 0',
                height: '50px',
                fontSize: '16px',
                textAlign: 'left',
            }}
            inputProps={{ // Přidání vlastnosti inputProps
                style: { height: '10px' } // Nastavení výšky vstupního pole
              }}
            
            InputProps={{
                endAdornment: (
                    <>
                      {unit && <InputAdornment position="end" style={{ marginRight: '20px' }}>{unit}</InputAdornment>}
                      <InputAdornment position="end">
                        {isId && inputLookup && (
                          <IconButton onClick={() => window.open(`/detail-view?tableEntita=${zdroj}&idUn=${inputLookup}&entita=&page=${param.page}`)}>
                            <LinkIcon />
                          </IconButton>
                        )}
                        {inputLookup && (
                        <IconButton onClick={() => handleEntityEdit('')}>
                            <CloseIcon />
                        </IconButton>
                        )}
                        <IconButton onClick={() => openNewWindow()}>
                          <ArrowDropDown />
                        </IconButton>
                      </InputAdornment>
                    </>
                ),
            }}
            />
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Hledat Modal"
            style={{
                content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '80%', // Nastavení šířky na 80% obrazovky
                height: '80%', // Nastavení výšky na 80% obrazovky
                }
            }}
            >
                <HledatComponent param={param} onEntityClick={handleEntityClick} />
            </Modal>
  </FormControl>
  </Box>
        );
    }
    
};

export default LookupComponent;